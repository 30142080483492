import React from 'react';

import { Infrared } from '../../../ir/Infrared/Infrared';
import Watchdog from '../../../watchdog/Watchdog';
import Schedule from '../../../schedule/Schedule';
import DeviceUsers from '../../DeviceUser/DeviceUsers';

export function RenderSkills({
  skills,
  id,
  dateOffline,
  physicalId,
  actions,
}) {
  return (
    <>

      {skills.includes('infrared') ? <Infrared /> : null}
      <br />
      {skills?.includes('users') ? <DeviceUsers deviceId={id} /> : null}
      <br />
      {skills?.includes('watchdog') ? <Watchdog deviceId={id} physicalId={physicalId} offline={Boolean(dateOffline)} /> : ''}
      <br />
      {skills?.includes('schedule') ? <Schedule deviceId={id} actions={actions} offline={Boolean(dateOffline)} /> : ''}
    </>
  );
}
