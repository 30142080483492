import React, { useState } from 'react';
import { BsPencil, BsTrash } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import { Modal, Popup } from 'semantic-ui-react';
import api from '../../../../api/api';
import ButtonResponsive from '../../../elements/ButtonResponsive';
import { popupStyle } from '../../details/PopupStyle';
import EditableLabel from '../../../elements/EditableLabel/EditableLabel';
import { RenderLoading } from '../../../renders/RenderLoading';
import './infrared.less';

export function ItemListInfrared({
  control,
  deviceId,
  indexItem,
  openModal,
  removeControl,
  isOpenModal,
  changeIsOpenModal,
  nameLabel,
  setError,
  equipment,
}) {
  const styleBtnMdoal = {
    width: '120px',
    height: '45px',
    fontSize: '16px',
  };
  const [nameControl, setNameControl] = useState(nameLabel);
  const [isChangingValueDeveice, setIsChangingValueDeveice] = useState(false);
  const editNameControl = async (name) => {
    setIsChangingValueDeveice(true);
    try {
      await api.put(`devices/${deviceId}/ir/${control._id}/edit`, {
        name,
      });
      setNameControl(name);
    } catch (error) {
      setError(error.response.data.error);
    }
    setIsChangingValueDeveice(false);
  };
  return (
    <>
      <div
        key={control._id}
        className="item container"
      >
        <div className="control">
          <Link to={`/devices/${deviceId}/control/${control._id}`}>
            <img className="device-icon" alt="Ícone representando um controle" src="/control.png" />
          </Link>
          <p className="editLabel">
            {
              !isChangingValueDeveice
                ? (
                  <EditableLabel
                    value={nameControl}
                    onSubmit={editNameControl}
                  />
                )
                : (
                  <RenderLoading
                    className="loading"
                  />
                )
          }
          </p>
          <section className="actions">
            {
              equipment === 'custom' ? (
                <Popup
                  style={popupStyle}
                  content="Editar Controle."
                  trigger={(
                    <Link to={`/devices/${deviceId}/control/${control._id}/${true}`}>
                      <BsPencil
                        className="device-icon pencil"
                      />
                    </Link>
)}
                />
              ) : null
            }
            <Popup
              style={popupStyle}
              content="Apagar Controle."
              trigger={(
                <BsTrash className="device-icon trash" onClick={() => openModal(indexItem)} />)}
            />
          </section>
        </div>
      </div>
      <Modal
        centered={false}
        onClose={() => changeIsOpenModal(false)}
        open={isOpenModal}
        size="mini"
      >
        <Modal.Header>
          Deseja realmente apagar o controle
          {' '}
          { control.name}
        </Modal.Header>
        <Modal.Content>
          <Modal.Description className="modal">
            <ButtonResponsive
              content="Sim"
              primary
              onClick={async () => removeControl()}
              style={styleBtnMdoal}
            />
            <ButtonResponsive
              content="Não"
              primary
              floated="right"
              onClick={() => changeIsOpenModal(false)}
              style={styleBtnMdoal}
            />
          </Modal.Description>
        </Modal.Content>
      </Modal>
    </>
  );
}
