/* eslint-disable react/jsx-no-bind */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import api from '../../../../api/api';
import ButtonResponsive from '../../../elements/ButtonResponsive';
import './infrared.less';
import { ItemListInfrared } from './ItemListInfrared';

export function Infrared() {
  const [controls, setControls] = useState([]);

  const [openModalDelete, setIsOpenModalDelete] = useState(false);
  const [idControlForDelete, setIdControlForDelete] = useState('');
  const [existsError, setExistsError] = useState('');
  const { id } = useParams();
  useEffect(() => {
    async function getControls() {
      try {
        const { data } = await api.get(`/devices/${id}/ir/controls`);
        setControls(data.controls);
      } catch (error) {
        setExistsError(error.response.data.error);
      }
    }
    getControls();
  }, []);
  function openModal(index) {
    controls.forEach((control, indx) => {
      if (indx === index) {
        setIdControlForDelete(control._id);
      }
    });
    setIsOpenModalDelete(true);
  }
  async function removeControl() {
    try {
      await api.put(`devices/${id}/ir/${idControlForDelete}/remove`);
      setControls(controls.filter((control) => control._id !== idControlForDelete));
      setIsOpenModalDelete(false);
    } catch (error) {
      setExistsError(error.response.data.error);
    }
  }
  return (
    <>
      <div style={{ width: '100%', minHeight: '7.5rem', margin: '1rem 0' }}>
        <ButtonResponsive
          content="Adicionar Controle"
          as={Link}
          to={`/devices/${id}/ir/new`}
          icon="cog"
          primary
          floated="right"
        />
        <h2>Controles</h2>
        <div className="ui raised segment chidren-devices">
          <div className="ui divided unstackable items">
            {
            controls && controls.map((control, index) => {
              return (
                <ItemListInfrared
                  key={control._id}
                  control={control}
                  removeControl={removeControl}
                  indexItem={index}
                  deviceId={id}
                  changeIsOpenModal={setIsOpenModalDelete}
                  isOpenModal={openModalDelete}
                  openModal={openModal}
                  nameLabel={control.name}
                  setError={setExistsError}
                  equipment={control.equipment}
                />
              );
            })
          }
          </div>
        </div>
      </div>
      { existsError ? <span style={{ color: '#f00' }}>{existsError}</span> : null}
    </>
  );
}
