import React, { useEffect, useState } from 'react';
import { Checkbox, Popup } from 'semantic-ui-react';
import { GiPowerButton } from 'react-icons/gi';
import { attDevice, attDeviceSocket, attValueDevice } from '../../../../api/socket';

import api from '../../../../api/api';
import { popupStyle } from '../PopupStyle';
import './deviceChildTurn.less';

const DeviceChildTurnOn = ({ child, onChangeState, on, disabled }) => {
  const [sendingAction, setSendingAction] = useState(false);
  const [errorSaving, setErrorSaving] = useState(false);
  const handleTurnOn = async (event) => {
    event.preventDefault();
    if (disabled) return;
    setSendingAction(true);
    const action = on ? 'turn-off' : 'turn-on';
    try {
      await api.post(`devices/${child._id}/action/${action}/${child.physicalId}`);
      attValueDevice(child.physicalId, !on);
      attDeviceSocket(child.physicalId, onChangeState);
      onChangeState(!on);
    } catch {
      setErrorSaving(true);
    } finally {
      setSendingAction(false);
    }
  };

  const popupContent = () => {
    if (disabled) return 'Dispositivo offline';
    return on ? 'Desligar' : 'Ligar';
  };
  const pulse = () => {
    return child.model.actions.includes('pulse');
  };
  const actionPulse = async (event) => {
    event.preventDefault();
    try {
      setSendingAction(true);
      const { intervals, times } = child?.settings;
      await api.post(`devices/${child._id}/action/pulse/${child.physicalId}`, {
        intervals,
        times,
      });
    } catch (error) {
      setErrorSaving(true);
    }
    setSendingAction(false);
  };

  useEffect(() => {
    attDevice(child.physicalId);
  }, []);

  useEffect(() => {
    attDeviceSocket(child.physicalId, onChangeState);
  }, [child, on]);
  return (
    pulse() ? (
      <Popup
        style={popupStyle}
        content="Enviar pulso"
        trigger={(
          <div className="pulse">
            <Popup
              content="Não foi possível se comunicar com o dispositivo."
              on="click"
              open={errorSaving}
              onClose={() => setErrorSaving(false)}
              inverted
              className="red"
              trigger={(
                <GiPowerButton
                  className="turn"
                  onClick={actionPulse}
                  disabled={disabled}
                />
              )}
            />
            <div className={`ui inverted dimmer ${sendingAction ? 'active' : ''}`}>
              <div className="ui loader" />
            </div>
          </div>
        )}
      />
    ) : (
      <Popup
        content={popupContent()}
        trigger={(
          <div className="turnon-button">
            <Popup
              content="Não foi possível se comunicar com o dispositivo."
              on="click"
              open={errorSaving}
              onClose={() => setErrorSaving(false)}
              inverted
              className="red"
              trigger={(
                <Checkbox
                  toggle
                  className="green"
                  checked={on}
                  onMouseDown={handleTurnOn}
                  disabled={disabled}
                />
)}
            />
            <div className={`ui inverted dimmer ${sendingAction ? 'active' : ''}`}>
              <div className="ui loader" />
            </div>
          </div>
    )}
      />
    )

  );
};

export default DeviceChildTurnOn;
