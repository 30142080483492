import React from 'react';
import { DateTime } from 'luxon';
import { Message } from 'semantic-ui-react';

export function RenderOffline({ dateOffline }) {
  if (!dateOffline) return '';
  const dateOfflineFormated = DateTime.fromISO(dateOffline).toFormat('dd/MM/yyyy HH:mm');
  return (
    <Message
      header={`Dispositivo offline desde ${dateOfflineFormated}`}
      warning
      icon="exclamation triangle"
    />
  );
}
