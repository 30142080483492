import api from '../api/api';
import { connect } from '../api/socket';

let pathAfterLogin = '/devices';

/**
 * Atualiza a sessão do usuário logado
 * @param {*} userData { user, token }
 */
const updateUserData = (userData) => {
  localStorage.setItem('user', JSON.stringify(userData));
  connect(userData.token);
};

const verifySignupCode = async (validationId, code) => {
  const { data: userData } = await api.post('auth/signup/code', { validationId, code });
  updateUserData(userData);
  return pathAfterLogin;
};

const verifyCode = async (validationId, code) => {
  const { data: userData } = await api.post('auth/password/forgot/code', { validationId, code });
  updateUserData(userData);
  return true;
};

const resetPassword = async (password) => {
  await api.post('auth/password/reset', { password });
  return pathAfterLogin;
};

const login = async (email, password) => {
  const { data: userData } = await api.post('auth/login', { email, password });
  updateUserData(userData);
  return pathAfterLogin;
};

const logout = async () => {
  localStorage.removeItem('user');
};

const getUserData = () => {
  return JSON.parse(localStorage.getItem('user'));
};

const getToken = () => {
  const userData = getUserData();
  return userData ? userData.token : false;
};

const currentUser = () => {
  const userData = getUserData();
  return userData ? userData.user : false;
};

const isAuthed = () => {
  return getToken() !== false;
};

const setPathAfterLogin = (path) => {
  pathAfterLogin = path;
};

api.interceptors.request.use((config) => {
  const token = getToken();
  // eslint-disable-next-line no-param-reassign
  config.headers.Authorization = token ? `Bearer ${getToken()}` : '';
  return config;
});

// Ao iniciar verificar se o usuário está logado e inicia o socket
if (isAuthed()) {
  connect(getToken());
}

export default {
  login,
  logout,
  verifyCode,
  getToken,
  isAuthed,
  setPathAfterLogin,
  verifySignupCode,
  resetPassword,
  currentUser,
};
