import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import EditableLabel from '../../../../elements/EditableLabel/EditableLabel';
import { RenderLoading } from '../../../../renders/RenderLoading';
import api from '../../../../../api/api';
import './Outlet.less';

export function Outlet({ idOutlet, outletName, description }) {
  const [valueDevice, setValueDevice] = useState(outletName);
  const [isChangingValueDeveice, setIsChangingValueDeveice] = useState(false);
  const [error, setError] = useState(false);
  const changeDeviceName = async (name) => {
    if (name === outletName) return;
    setIsChangingValueDeveice(true);
    await api
      .put(`devices/${idOutlet}`, { name })
      .then(() => {
        setValueDevice(name);
        setError(false);
      })
      .catch(() => {
        setError(true);
      });
    setIsChangingValueDeveice(false);
  };
  return (
    <div className="outlet">
      <Link to={`/devices/${idOutlet}`}>
        <img className="device-icon" alt="Ícone representando uma tomada" src="/tomada-icone.png" />
      </Link>
      <div className="name">
        <p className="header header-child">
          {
              !isChangingValueDeveice
                ? (
                  <EditableLabel
                    value={valueDevice}
                    onSubmit={changeDeviceName}
                  />
                )
                : (
                  <RenderLoading
                    className="loading"
                  />
                )
          }
          <span className={`span-error ${error ? 'error' : ''}`}>Erro ao alterar o nome do dispositivo</span>
          <span className="description">
            {description}
          </span>
        </p>
      </div>
    </div>
  );
}
