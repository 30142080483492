import { React } from 'react';
import { DateTime } from 'luxon';
import { Card, Grid, Header, Segment } from 'semantic-ui-react';

export default function CardEvent({ data }) {
  return (
    <Card fluid centered>
      <Card.Content>
        <Card.Header>{data?.device?.name}</Card.Header>
        <Card.Meta>
          {data?.name}
          {' '}
          ||
          {' '}
          <span>
            {data?.type}
          </span>
          {' '}
          || Horário:
          {' '}
          { DateTime.fromISO(data.createdAt).toFormat('dd/MM/yyyy HH:mm')}
        </Card.Meta>
        <Card.Meta>
          <span>
            Serial Number:
            {' '}
            {data?.physicalId}
          </span>
        </Card.Meta>
        <Card.Description>
          <span>{data?.text}</span>
          {data?.user ? (
            <>
              <br />
              <span>
                Usuário:
                {' '}
                {data?.user?.name}
                { ' '}
                {data?.user?.surname}
                { ' || '}
                {data?.user?.email}
              </span>
            </>
          ) : null}
        </Card.Description>
      </Card.Content>
      <Card.Content extra>
        {data?.data ? (
          <Grid>
            <Grid.Column width={10}>
              <Segment>
                <Header>Informações de debug</Header>
                <pre style={{ overflowX: 'auto' }}>
                  {JSON.stringify(data?.data, null, 2)}
                </pre>
              </Segment>
            </Grid.Column>
          </Grid>
        ) : null}
      </Card.Content>
    </Card>
  );
}
