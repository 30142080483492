import React, { useState } from 'react';
import { Button, Icon, Popup } from 'semantic-ui-react';
import { Field, Form, Formik } from 'formik';

import api from '../../../api/api';
import { getMessage } from '../../../api/errors';

const DeviceChildRestart = ({ child, onChangeState, on, disabled }) => {
  const [sending, setSending] = useState(false);
  const [error, setError] = useState(null);
  const [popupOpen, setPopupOpen] = useState(false);

  const handleSubmit = async (values) => {
    setError(null);
    setSending(true);
    onChangeState(false);
    try {
      await api.post(`devices/${child._id}/action/restart/${child.physicalId}`, values);
      onChangeState(true);
      setPopupOpen(false);
    } catch (e) {
      setError(getMessage(e.response.data));
      onChangeState(true);
    } finally {
      setSending(false);
    }
  };

  const renderError = () => {
    return error
      ? (
        <div className="ui small negative message">
          <p>{error}</p>
        </div>
      )
      : '';
  };

  const handleClose = () => {
    setPopupOpen(false);
    setError(null);
  };

  // Só exibe a opção de restart se o device estiver ligado ou se estiver processando o restart
  if (!on && !sending) return null;

  return (
    <Popup
      on="click"
      open={popupOpen && !disabled}
      onOpen={() => setPopupOpen(true)}
      onClose={handleClose}
      trigger={(
        <div>
          <Popup
            content="Reiniciar"
            trigger={(
              <Button className="restart" basic icon disabled={disabled}>
                <Icon name="redo" />
              </Button>
            )}
          />
        </div>
      )}
    >
      <Formik initialValues={{ interval: 5 }} onSubmit={handleSubmit}>
        <Form className={`ui form restart ${sending ? 'loading' : ''}`}>
          <div className="field">
            <label htmlFor="email">Aguardar (em segundos)</label>
            <Field
              name="interval"
              required
              type="number"
            />
          </div>
          {renderError()}
          <Button primary content="Reiniciar" type="submit" />
        </Form>
      </Formik>
    </Popup>
  );
};

export default DeviceChildRestart;
