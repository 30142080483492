import React from 'react';
import { Redirect, useLocation } from 'react-router-dom';

import auth from '../../services/auth';

// const PrivateRoute = ({ component: Component, ...rest }) => {
//   const authed = auth.isAuthed();
//   return (
//     <Route
//       {...rest}
//       render={(props) => (authed
//         ? <Component {...props} />
//         : <Redirect to={{ pathname: '/login', state: { from: props.location } }} />)}
//     />
//   );
// };

const PrivateRoute = ({ children }) => {
  const authed = auth.isAuthed();
  const location = useLocation();
  if (authed) {
    return children;
  }
  auth.setPathAfterLogin(location.pathname);
  return <Redirect to={{ pathname: '/login' }} />;
};

export default PrivateRoute;
