import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import { Button, Input } from 'semantic-ui-react';
import { Link, useHistory } from 'react-router-dom';
// import api from '../../api/api';
import Layout from './Layout';
import SemanticField from '../elements/SemanticField';
import auth from '../../services/auth';
import { getMessage } from '../../api/errors';
import PasswordField from './PasswordField';

const Login = () => {
  const history = useHistory();
  const [error, setError] = useState(null);
  const [sending, setSending] = useState(false);
  const initialValues = {
    email: '',
    password: '',
  };

  const handleSubmit = async (values) => {
    setSending(true);
    try {
      const pathAfterLogin = await auth.login(values.email, values.password);
      history.push(pathAfterLogin);
    } catch (e) {
      setError(getMessage(e.response?.data));
    } finally {
      setSending(false);
    }
  };

  const renderError = (msg) => {
    return msg ? (
      <div className="ui negative message">
        <p>{msg}</p>
      </div>
    ) : '';
  };

  return (
    <Layout>
      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        <Form className={`ui large form ${sending ? 'loading' : ''}`}>
          <div className="field">
            <SemanticField
              name="email"
              component={Input}
              icon="user"
              iconPosition="left"
              required
              placeholder="E-mail"
              size="big"
              autoComplete="on"
            />
          </div>
          <div className="field">
            <PasswordField />
          </div>
          <div className="field">
            <Link to="/login/password/forgot">Esqueceu a senha?</Link>
          </div>
          { renderError(error) }
          <Button
            type="submit"
            content="Entrar"
            primary
            fluid
            size="huge"
          />
          <div className="horizontal-bar">
            <span>OU</span>
          </div>
          <Button
            as={Link}
            to="/login/signup"
            content="Criar uma conta"
            basic
            color="blue"
            fluid
            size="huge"
          />
        </Form>
      </Formik>
    </Layout>
  );
};

export default Login;
