import React from 'react';
import { Button } from 'semantic-ui-react';

const ButtonResponsive = ({ ...fieldProps }) => (
  <>
    <Button {...fieldProps} className="mini-mobile hidden" />
    <Button {...fieldProps} content={null} className="mini-mobile only" />
  </>
);

export default ButtonResponsive;
