import React from 'react';
import './DeviceToken.less';

const DeviceToken = ({ device }) => {
  return (
    <div className="div-token">
      <h2>
        Senha de acesso
      </h2>
      <div className="ui raised segment chidren-devices">
        <p>
          {`Para acessar o dispositivo a partir da rede interna, utilize os IPs ${device.info?.ipWifi} ( Wi-Fi ) ${device.info?.ipEthernet ? `ou ${device.info?.ipEthernet} ( Ethernet )` : ''}  e a senha ${device.token}`}
        </p>
      </div>
    </div>
  );
};

export default DeviceToken;
