import React, { useState } from 'react';
import { Modal, Button } from 'semantic-ui-react';
import { Formik, Field, Form } from 'formik';

import api from '../../../api/api';

const UserAdd = ({ deviceId, onSave, children }) => {
  const [open, setOpen] = useState(false);
  const [errorSaving, setErrorSaving] = useState(false);
  const [sending, setSending] = useState(false);
  const initialValues = {
    email: '',
  };

  const handleSubmit = async (values) => {
    setSending(true);
    setErrorSaving(false);
    try {
      const { data: newUser } = await api.post(`devices/${deviceId}/user`, values);
      onSave(newUser);
      setOpen(false);
    } catch {
      setErrorSaving(true);
    } finally {
      setSending(false);
    }
  };

  return (
    <Modal
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      size="mini"
      trigger={children}
    >
      <Modal.Header>Adicionar usuário</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <Formik initialValues={initialValues} onSubmit={handleSubmit}>
            <Form id="form-id" className={`ui form ${sending ? 'loading' : ''}`}>
              <div className="field">
                <label htmlFor="email">Email</label>
                <Field
                  name="email"
                  required
                  placeholder="Informe o email"
                />
              </div>
              {
                errorSaving
                  ? (
                    <div className="ui negative message">
                      <div className="header">
                        Não foi possível adicionar o usuário
                      </div>
                      <p>Tente novamente</p>
                    </div>
                  )
                  : ''
              }
            </Form>
          </Formik>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={() => setOpen(false)}>
          Cancelar
        </Button>
        <Button
          content="Adicionar"
          labelPosition="right"
          icon="checkmark"
          form="form-id"
          positive
        />
      </Modal.Actions>
    </Modal>
  );
};

export default UserAdd;
