import React, { useState } from 'react';
import { Formik, Form, ErrorMessage } from 'formik';
import { Button, Input } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { DateTime } from 'luxon';
import * as Yup from 'yup';

import api from '../../api/api';
import Layout from './Layout';
import SemanticField from '../elements/SemanticField';
import SignupCode from './SignupCode';
import { getMessage } from '../../api/errors';
import PasswordField from './PasswordField';

const Signup = () => {
  const [sending, setSending] = useState(false);
  const [error, setError] = useState(null);
  const [validationId, setValidationId] = useState(null);
  const [codeTimeout, setCodeTimeout] = useState(null);
  const initialValues = {
    name: '',
    surname: '',
    email: '',
  };

  const handleSubmit = async (values) => {
    setSending(true);
    setError(null);
    try {
      const { data: response } = await api.post('auth/signup', values);
      const expireDate = DateTime.fromISO(response.expiresAt);
      const duration = expireDate.diff(DateTime.now(), 'seconds').toObject();
      const timeout = Math.round(duration.seconds);
      setCodeTimeout(timeout);
      setValidationId(response.id);
    } catch (e) {
      setError(getMessage(e.response.data));
    } finally {
      setSending(false);
    }
  };

  const SignupSchema = Yup.object().shape({
    // firstName: Yup.string()
    //   .min(2, 'Too Short!')
    //   .max(50, 'Too Long!')
    //   .required('Required'),
    // lastName: Yup.string()
    //   .min(2, 'Too Short!')
    //   .max(50, 'Too Long!')
    //   .required('Required'),
    // email: Yup.string().email('Email inválido'),
  });

  const renderError = (msg) => {
    return msg ? (
      <div className="ui negative message">
        <p>{msg}</p>
      </div>
    ) : '';
  };

  const handleClickBack = () => {
    setValidationId(null);
    setError(null);
  };

  const renderForm = () => {
    return (
      <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={SignupSchema}>
        <Form className={`ui large form ${sending ? 'loading' : ''}`}>
          <div className="field ui two columns grid fullname">
            <div className="column">
              <SemanticField
                name="name"
                component={Input}
                required
                placeholder="Nome"
                size="big"
              />
            </div>
            <div className="column">
              <SemanticField
                name="surname"
                component={Input}
                required
                placeholder="Sobrenome"
                size="big"
              />
            </div>
          </div>
          <div className="field">
            <SemanticField
              name="email"
              component={Input}
              icon="user"
              iconPosition="left"
              required
              placeholder="E-mail"
              size="big"
            />
            <ErrorMessage name="email" render={renderError} />
          </div>
          <div className="field">
            <PasswordField />
          </div>
          { renderError(error) }
          <div className="field">
            <Button
              content="Criar conta"
              primary
              fluid
              size="huge"
            />
          </div>
          <div className="field">
            <Link to="/login">Já tem uma conta?</Link>
          </div>
        </Form>
      </Formik>
    );
  };

  return (
    <Layout>
      {
        validationId
          ? (
            <SignupCode
              validationId={validationId}
              timeout={codeTimeout}
              onClickBack={handleClickBack}
            />
          )
          : renderForm()
      }
    </Layout>
  );
};

export default Signup;
