// import './App.css';
import React, { useState } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from 'react-router-dom';
import { Sidebar } from 'semantic-ui-react';
import './App.less';
import LogsDashboard from './components/logger/LogsDashboard';
import { ControlIr } from './components/device/ir/control/Control';
import { AddNewIr } from './components/device/ir/AddNewIr/AddNewIr';
import Login from './components/login/Login';
import Signup from './components/login/Signup';
import ForgotPassword from './components/login/ForgotPassword';
import DeviceDetails from './components/device/details/DeviceDetails/DeviceDetails';
import SensorDetails from './components/device/sensor/SensorDetails/SensorDetails';
import DeviceNew from './components/device/dashboard/DeviceNew';
import TopMenu from './components/layout/TopMenu';
import SidebarMobile from './components/layout/SidebarMobile';
import SidebarDesktop from './components/layout/SidebarDesktop';
import SignupCode from './components/login/SignupCode';
import PrivateRoute from './components/login/PrivateRoute';
import PasswordReset from './components/login/PasswordReset';
import PasswordChange from './components/user/PasswordChange';
import Dashboard from './components/device/dashboard/Dashboard';

function App() {
  const [sidebarVisible, setSidebarVisible] = useState(false);

  return (
    <Router>
      <Switch>
        <Route exact path="/login" component={Login} />
        <Route exact path="/login/signup" component={Signup} />
        <Route exact path="/login/signup/code" component={SignupCode} />
        <Route exact path="/login/password/forgot" component={ForgotPassword} />
        <Route exact path="/login/password/reset" component={PasswordReset} />
        <PrivateRoute>
          <TopMenu onCallSidebar={() => setSidebarVisible(true)} />
          <Sidebar.Pushable className="think">
            <SidebarMobile visible={sidebarVisible} onHide={() => setSidebarVisible(false)} />
            <Sidebar.Pusher dimmed={sidebarVisible}>
              <SidebarDesktop />
              <div className="page">
                <Switch>
                  <Route exact path="/" component={Dashboard} />
                  <Route exact path="/devices" component={Dashboard} />
                  <Route exact path="/devices/new/:serialNumberParam" component={DeviceNew} />
                  <Route exact path="/devices/new" component={DeviceNew} />
                  <Route exact path="/devices/:id/ir/new" component={AddNewIr} />
                  <Route exact path="/devices/:id" component={DeviceDetails} />
                  <Route exact path="/devices/:id/control/:idControl" component={ControlIr} />
                  <Route exact path="/devices/:id/control/:idControl/:edit" component={ControlIr} />
                  <Route exact path="/devices/:deviceId/sensor/:sensorName" component={SensorDetails} />
                  <Route exact path="/user/password/change" component={PasswordChange} />
                  <Route exact path="/logs/:physicalId" component={LogsDashboard} />
                  <Route exact path="/logs/" component={LogsDashboard} />
                </Switch>
              </div>
            </Sidebar.Pusher>
          </Sidebar.Pushable>
        </PrivateRoute>
      </Switch>
    </Router>
  );
}

export default App;
