import React, { useState, useEffect } from 'react';
import { Modal, Button, Grid } from 'semantic-ui-react';
import { Formik, Field, Form } from 'formik';
import { DateTime } from 'luxon';

const UserAdd = ({ show, range, onConfirm, onClose }) => {
  const dateTimeNow = DateTime.utc().toISO();
  const [finalDate, setFinalDate] = useState(dateTimeNow);
  const [inicialDate, setInicialDate] = useState(dateTimeNow);
  const [errorDate, setErrorDate] = useState(false);
  const [textError, setTextError] = useState('');
  const [initialValues, setInitialValues] = useState({});
  useEffect(() => {
    setInitialValues({
      from: DateTime.fromISO(range?.from).toFormat('yyyy/MM/dd hh:mm'),
      to: DateTime.fromISO(range?.to).toFormat('yyyy/MM/dd hh:mm'),
    });
  }, []);
  function handleDate(evDate, functionSetDate) {
    const newDateChange = DateTime.fromISO(evDate.target.value).toUTC().toISO();
    setInitialValues({
      ...initialValues,
      [evDate.target.name]: newDateChange,
    });
    functionSetDate(newDateChange);
  }
  useEffect(() => {
    if (finalDate > dateTimeNow || inicialDate > dateTimeNow) {
      setTextError('Data inicial/final inválida.');
      setErrorDate(true);
    } else if (inicialDate > finalDate) {
      setErrorDate(true);
      setTextError('Data inicial inválida.');
    } else {
      setErrorDate(false);
    }
    setInitialValues({
      from: inicialDate,
      to: finalDate,
    });
  }, [inicialDate, finalDate]);
  async function handleSubmit() {
    await onConfirm(initialValues);
    onClose();
  }
  return (
    <Modal
      onClose={onClose}
      open={show}
      size="mini"
    >
      <Modal.Header>Período customizado</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <Formik initialValues={initialValues} onSubmit={() => handleSubmit}>
            <Form id="form-id" className="ui form">
              <Grid rows={2} className="ui centered">
                <Grid.Row>
                  <div className="field">
                    <label htmlFor="from">Início</label>
                    <Field
                      name="from"
                      required
                      type="datetime-local"
                      onChange={(e) => {
                        handleDate(e, setInicialDate);
                      }}
                    />
                  </div>
                </Grid.Row>
                <Grid.Row>
                  <div className="field">
                    <label htmlFor="to">Fim</label>
                    <Field
                      name="to"
                      required
                      type="datetime-local"
                      onChange={(e) => handleDate(e, setFinalDate)}
                    />
                  </div>
                </Grid.Row>
                <Grid.Row>
                  <span style={errorDate ? { color: '#f00' } : { display: 'none' }}>{textError}</span>
                </Grid.Row>
              </Grid>
            </Form>
          </Formik>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={onClose} type="button">
          Cancelar
        </Button>
        <Button
          content="Confirmar"
          labelPosition="right"
          icon="checkmark"
          form="form-id"
          positive
          disabled={errorDate}
          type="submit"
        />
      </Modal.Actions>
    </Modal>
  );
};

export default UserAdd;
