import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'semantic-ui-react';

import './addNewIr.less';

export function ErrorAddControl({ path }) {
  return (
    <div className="errorAdd">
      <h2>
        Erro ao adicionar o controle ao dispositivo

      </h2>
      <Link to={path}>
        <Button
          content="Voltar"
          labelPosition="right"
          icon="checkmark"
          positive
          type="submit"
          className="btn"
        />
      </Link>
    </div>
  );
}
