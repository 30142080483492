/* eslint-disable no-console */
/* eslint-disable react/jsx-no-bind */
import React, { useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { Button } from 'semantic-ui-react';
import { RenderLoading } from '../../../renders/RenderLoading';
import api from '../../../../api/api';
import './addNewIr.less';
import { ErrorAddControl } from './ErrorAddControl';

export function AddNewIr() {
  const [equipment] = useState('Custom');
  const [manufacture] = useState('');
  const [type] = useState();

  const [error, setError] = useState('');
  const [name, setName] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [errorAddControl, setErrorAddControl] = useState(false);
  const { id } = useParams();
  const [disabled] = useState(false);
  const history = useHistory();
  async function submit(e) {
    e.preventDefault();
    try {
      setIsLoading(true);
      if (equipment === 'Custom') {
        await api.put(`/devices/${id}/ir/add`, {
          equipment,
          name,
        });
        history.push(`/devices/${id}`);
      } else {
        const { data } = await api.get(`/devices/${id}/ir/control/${equipment}/${manufacture}/${type}`);
        await api.put(`/devices/${id}/ir/add`, {
          idControl: data._id,
          equipment,
          manufacture,
          type,
          name,
        });
        history.push(`/devices/${id}`);
      }
    } catch (err) {
      setErrorAddControl(true);
      setError(error.response.data.error);
    }
    setIsLoading(false);
  }

  if (isLoading) return RenderLoading();
  if (errorAddControl) return <ErrorAddControl path={`/devices/${id}`} />;
  return (
    <div className="pageAddIr">
      <h1>Adicionar Controle</h1>
      <form className="ui form" onSubmit={async (e) => submit(e)}>
        <div className="ui field">
          <label htmlFor="serialNumber">Nome do controle</label>
          <input
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
            placeholder="Informe o nome do controle"
          />
        </div>
        <div className="divBtns">
          <Button
            content="Cancelar"
            icon="close"
            as={Link}
            to={`/devices/${id}`}
            className="btn"
          />
          <Button
            disabled={disabled}
            content="Adicionar Controle"
            labelPosition="right"
            icon="checkmark"
            positive
            type="submit"
            className="btn"
          />
        </div>
      </form>
      <p className="errorSave">{error ?? ''}</p>
    </div>
  );
}
