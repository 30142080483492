import React from 'react';

export function TableInfoDevice({ infoTable }) {
  if (infoTable.titulo === 'Ethernet' && !infoTable.infosLinhas[0].value) {
    return null;
  }
  return (
    <>
      <h2>
        {infoTable.titulo}
      </h2>
      <table className="ui definition unstackable table">
        <tbody>
          {
            infoTable.infosLinhas.map((linhas) => (
              <tr key={`${linhas.label}-${linhas.value}`} style={{ boxSizing: 'border-box' }}>
                <td style={{ width: '30vw' }}>{linhas.label}</td>
                <td style={{ maxWidth: '80vw', overflowX: 'scroll' }}>{linhas.value}</td>
              </tr>
            ))
          }
        </tbody>
      </table>
    </>
  );
}
