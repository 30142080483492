/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { RenderLoading } from '../../../renders/RenderLoading';
import './controls.less';
import { sendSignal } from './sendSignal';

export function TVControl() {
  const [errorSendSignalIr, setErrorSendSignalIr] = useState('');
  const [isLoadingSignal, setIsLoadingSignal] = useState(false);
  const { id, idControl } = useParams();

  if (isLoadingSignal) return <RenderLoading />;
  return (
    <div className="ui card controls">
      <div
        className="icone power"
        onClick={async () => sendSignal({
          id,
          idControl,
          button: 'POWER',
          changeError: setErrorSendSignalIr,
          changeLoading: setIsLoadingSignal,
        })}
      >
        <i className="power off icon icons" />
      </div>
      <div className="content">
        <div className="btnsVolums">
          <div
            className="icone up"
            onClick={async () => sendSignal({
              id,
              idControl,
              button: 'VOLUME_UP',
              changeError: setErrorSendSignalIr,
              changeLoading: setIsLoadingSignal,
            })}
          >
            <i className="volume up icon icons" />
          </div>
          <div
            className="icone down"
            onClick={async () => sendSignal({
              id,
              idControl,
              button: 'VOLUME_DOWN',
              changeError: setErrorSendSignalIr,
              changeLoading: setIsLoadingSignal,
            })}
          >
            <i className="volume down icon icons" />
          </div>
        </div>
        <div className="btnsVolums">
          <div
            className="icone up"
            onClick={async () => sendSignal({
              id,
              idControl,
              button: 'CHANNEL_UP',
              changeError: setErrorSendSignalIr,
              changeLoading: setIsLoadingSignal,
            })}
          >
            <i className="chevron up icon icons" />
          </div>
          <div
            className="icone down"
            onClick={async () => sendSignal({
              id,
              idControl,
              button: 'CHANNEL_DOWN',
              changeError: setErrorSendSignalIr,
              changeLoading: setIsLoadingSignal,
            })}
          >
            <i className="chevron down icon icons" />
          </div>
        </div>
      </div>
      <div
        className="icone mute"
        onClick={async () => sendSignal({
          id,
          idControl,
          button: 'MUTE',
          changeError: setErrorSendSignalIr,
          changeLoading: setIsLoadingSignal,
        })}
      >
        <i className="volume off icon icons" />
      </div>
      {
        errorSendSignalIr ? (
          <p className="error">
            Erro ao enviar o sinal:
            {' '}
            {errorSendSignalIr}
          </p>
        ) : null
      }
    </div>
  );
}
