import React from 'react';

import DeviceListOwner from './DeviceListOwner';
import DeviceListShared from './DeviceListShared';
import './Dashboard.less';

const Dashboard = () => {
  return (
    <>
      <DeviceListOwner />
      <DeviceListShared />
    </>
  );
};

export default Dashboard;
