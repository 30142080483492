import { io } from 'socket.io-client';

export const socket = io(process.env.REACT_APP_WEBSOCKET_URL, { autoConnect: false });

export const connect = (token) => {
  socket.auth = { token };
  socket.connect();
};
// on é para escutar
export const addSensorsListener = (physicalId, callback) => {
  socket.on(`${physicalId}/sensors/update`, callback);
};

export const stopSensorsListener = (physicalId) => {
  socket.removeAllListeners(`${physicalId}/sensors/update`);
};

export const addEventListener = (physicalId, eventName, callback) => {
  socket.on(`${physicalId}/event/${eventName}`, callback);
};

export const stopEventListener = (physicalId, eventName) => {
  socket.removeAllListeners(`${physicalId}/event/${eventName}`);
};

export const addAlertListener = (physicalId, callback) => {
  socket.on(`${physicalId}/alert`, callback);
};

export const stopAlertListener = (physicalId) => {
  socket.removeAllListeners(`${physicalId}/alert`);
};

// emitir alguma informação
export const refreshUserDevices = () => {
  socket.emit('refreshUserDevices');
};

export const attDevice = (physicalId) => {
  socket.emit('attDevice', { physicalId });
};

export const attValueDevice = (physicalId, valueTurn) => {
  socket.emit('attValueDevice', { physicalId, valueTurn });
};

export const attDeviceSocket = (child, set) => {
  socket.on('attValueDevice', (dataSocket) => {
    if (child === dataSocket.physical) {
      set(dataSocket.turn);
    }
  });
};
