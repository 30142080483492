import React, { useRef, useEffect, useState } from 'react';
import { DateTime } from 'luxon';
import 'chartjs-plugin-annotation';
import 'chartjs-plugin-crosshair';
import { Message } from 'semantic-ui-react';
import api from '../../../api/api';
import { renderChart } from './RenderChart';
import { timeboxOptions } from './timeboxOptions';

const SensorChart = ({ deviceId, sensor, timebox, model }) => {
  const chartRef = useRef();
  const [loading, setLoading] = useState(true);
  const [containMeasure, setContainMeasure] = useState(true);
  const isInsideDay = (dataFrom, dataTo) => {
    const mFrom = new Date(dataFrom).getTime();
    const mTo = new Date(dataTo).getTime();
    const day = 1000 * 60 * 60 * 24;
    return (mTo - mFrom) <= day;
  };
  const findMeasure = (measure) => {
    if (measure.min != null || measure.max != null || measure.avg != null) {
      return measure;
    }
    return null;
  };
  useEffect(() => {
    let chart;
    const getData = async () => {
      setLoading(true);
      await api.get(`devices/${deviceId}/measurement/${sensor.sensorId}-${sensor.sensorType}`, {
        params: {
          timebox: timebox.type,
          from: timebox.range?.from,
          to: timebox.range?.to,
          timezone: DateTime.utc().zone,
        },
      }).then(({ data: measures }) => {
        const insideDay = isInsideDay(timebox.range?.from, timebox.range?.to);
        const existsMeasures = measures.find((measure) => findMeasure(measure));
        if (existsMeasures) {
          setContainMeasure(true);
          chart = renderChart(measures,
            insideDay, timebox, timeboxOptions, chartRef, sensor);
        } else {
          setContainMeasure(false);
        }
        setLoading(false);
      });
    };
    getData(chart);
    return () => {
      if (chart) chart.destroy();
    };
  }, [timebox]);

  return (
    <div className={`ui basic ${loading ? 'loading' : ''} segment zero-padding sensor chart`}>
      {
        containMeasure ? <canvas ref={chartRef} />
          : (
            <Message
              header={`Dispositivo sem medida de ${model.label}`}
              warning
              icon="exclamation triangle"
            />
          )
      }
    </div>
  );
};

export default SensorChart;
