import React from 'react';
import { Field } from 'formik';

const SemanticField = ({ component, ...fieldProps }) => (
  <Field {...fieldProps}>
    {({
      field: { value, onBlur, ...field },
      form: { setFieldValue, setFieldTouched },
      ...props
    }) => {
      return React.createElement(component, {
        ...fieldProps,
        ...field,
        ...props,
        ...(typeof value === 'boolean'
          ? { checked: value }
          : { value }),
        onChange: (e, { value: newValue, checked }) => {
          return setFieldValue(fieldProps.name, newValue || checked);
        },
        onBlur: (e, blurProps) => {
          return blurProps ? setFieldTouched(fieldProps.name, blurProps.value) : onBlur(e);
        },
      });
    }}
  </Field>
);

export default SemanticField;
