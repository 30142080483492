import React, { useState, useRef, useEffect } from 'react';
import { Popup } from 'semantic-ui-react';
import { popupStyle } from '../../device/details/PopupStyle';
import './EditableLabel.less';

// eslint-disable-next-line react/prop-types
const EditableLabel = ({ onSubmit, value }) => {
  const [editMode, setEditMode] = useState(false);
  const inputElem = useRef(null);
  const submit = (event) => {
    onSubmit(event.target.value);
    setEditMode(false);
  };

  const handleEnter = async (event) => {
    if (event.key === 'Enter') {
      submit(event);
    }
  };

  const enterEditMode = () => {
    setEditMode(true);
  };

  const renderInput = () => {
    return (
      <div className="ui input">
        <input
          ref={inputElem}
          type="text"
          defaultValue={value}
          onKeyPress={handleEnter}
          onBlur={submit}
          style={{ width: '100%', height: '2em', padding: '1em 0.25em' }}
        />
      </div>
    );
  };

  const renderText = () => {
    return (
      <span
        onClick={enterEditMode}
        role="button"
        tabIndex="0"
        onKeyPress={enterEditMode}
        className="text-editable"
      >
        {value}
        <Popup
          content="Editar o nome do label"
          style={popupStyle}
          trigger={(
            <i className="editable pencil alternate icon" style={{ cursor: 'pointer' }} />
          )}
        />
      </span>
    );
  };

  useEffect(() => {
    if (editMode) {
      inputElem.current.select();
    }
  }, [editMode]);

  return (
    editMode ? renderInput() : renderText()
  );
};

export default EditableLabel;
