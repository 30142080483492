import axios from 'axios';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  // timeout: 1000,
});

// api.interceptors.response.use((response) => response, (error) => {
//   return error;
// });

export default api;
