import React, { useEffect, useState } from 'react';

import UserAdd from '../../users/UserAdd';
import api from '../../../../api/api';
import ButtonResponsive from '../../../elements/ButtonResponsive';
import { RenderUsers } from './RenderUsers/RenderUsers';

const DeviceUsers = ({ deviceId }) => {
  const [accessList, setAccessList] = useState([]);

  useEffect(() => {
    async function fetchData() {
      const { data } = await api.get(`devices/${deviceId}/user/`);
      setAccessList(data);
    }

    fetchData();
  }, []);

  const handleCreation = (created) => {
    const newList = [...accessList, created];
    setAccessList(newList);
  };

  return (
    <div>
      <h2>
        Usuários
        <UserAdd deviceId={deviceId} onSave={handleCreation}>
          <ButtonResponsive content="Adicionar usuário" icon="user plus" primary floated="right" />
        </UserAdd>
      </h2>
      <RenderUsers id={deviceId} />
    </div>
  );
};

export default DeviceUsers;
