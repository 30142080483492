import React, { useState } from 'react';
import { Button, Icon, Popup } from 'semantic-ui-react';
import { Formik, Field, Form } from 'formik';

import api from '../../../api/api';
import { getMessage } from '../../../api/errors';

const WatchdogForm = ({ deviceId, onSave, onCancel, watchdog }) => {
  const [errorSaving, setErrorSaving] = useState(null);
  const [showConfig, setShowConfig] = useState(false);
  const [sending, setSending] = useState(false);
  const editMode = watchdog;
  const initialValues = watchdog || {
    pingTo: '',
    pingInterval: 1,
    wait: 1000,
    restartAttempts: 5,
    restartDelay: 5,
  };

  const create = async (values) => {
    const { data: newWatchdog } = await api.post(`devices/${deviceId}/watchdog`, values);
    onSave(newWatchdog);
  };

  const update = async (values) => {
    await api.put(`devices/${deviceId}/watchdog/${watchdog._id}`, values);
    onSave({ ...values, disabledAt: null });
  };

  const handleSubmit = async (values) => {
    setSending(true);
    setErrorSaving(null);
    try {
      if (editMode) {
        await update(values);
      } else {
        await create(values);
      }
    } catch (error) {
      setErrorSaving(error.response.data);
    } finally {
      setSending(false);
    }
  };

  const renderConfig = () => {
    return (
      <>
        <div className="field">
          <label htmlFor="pingInterval">
            Intervalo entre tentativas (minutos)
            <Popup content="Determina de quantos em quantos minutos será feito um ping para verificar se o serviço está ativo." trigger={<Icon name="question circle" />} />
          </label>
          <Field name="pingInterval" type="number" placeholder="0" />
        </div>
        <div className="field">
          <label htmlFor="wait">
            Ping timeout (milisegundos)
            <Popup content="Especifica a quantidade, em milisegundos, que o ping vai aguardar por uma resposta. Caso a resposta não chege nesse intervalo, será feito o reset." trigger={<Icon name="question circle" />} />
          </label>
          <Field name="wait" type="number" placeholder="0" />
        </div>
        <div className="field">
          <label htmlFor="restartAttempts">
            Número máximo de resets consecutivos
            <Popup content="Cada vez que o ping não tiver resposta, será feito um reset. Porém, caso o IP continue sem responder em seguida, quantas vezes o sistema deve tentar antes de desativar o watchdog? (informe 0 para não limitar o número de resets)" trigger={<Icon name="question circle" />} />
          </label>
          <Field name="restartAttempts" type="number" placeholder="0" />
        </div>
        <div className="field">
          <label htmlFor="restartDelay">
            Tempo entre desligar e ligar (segundos)
            <Popup content="O reset irá desligar a tomada e aguardar um tempo para ligá-la novamente. Quantos segundos o sistema deve aguardar para ligar a tomada novamente?" trigger={<Icon name="question circle" />} />
          </label>
          <Field name="restartDelay" type="number" placeholder="0" />
        </div>
      </>
    );
  };

  return (
    <div>
      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        <Form id="form-watchdog-id" className={`ui form ${sending ? 'loading' : ''}`}>
          <div className="field">
            <label htmlFor="pingTo">
              IP ou domínio
              <Popup content="Um ping será feito para este endereço com objetivo de saber se ele está respondendo." trigger={<Icon name="question circle" />} />
            </label>
            <Field name="pingTo" type="text" placeholder="0.0.0.0" required maxLength="128" />
          </div>
          {
            showConfig
              ? renderConfig()
              : <p><a href="#/" onClick={() => setShowConfig(true)}> Configurações avançadas </a></p>
          }
          {
            errorSaving
              ? (
                <div className="ui negative message">
                  <div className="header">
                    Erro ao tentar ativar este watchdog.
                  </div>
                  <p>{getMessage(errorSaving)}</p>
                </div>
              )
              : ''
          }
          <Button
            type="submit"
            content="Salvar e ativar"
            labelPosition="right"
            icon="checkmark"
            form="form-watchdog-id"
            positive
          />
          <Button
            type="button"
            content="Cancelar"
            onClick={onCancel}
          />
        </Form>
      </Formik>
    </div>
  );
};

export default WatchdogForm;
