import React from 'react';
import { Link } from 'react-router-dom';
import auth from '../../services/auth';

const TopMenu = ({ onCallSidebar }) => {
  return (
    <div className="ui top fixed menu think">
      <div className="left menu">
        <span className="item" onClick={onCallSidebar} onKeyPress={onCallSidebar} role="button" tabIndex="0">
          <i className="ui content icon" />
        </span>
      </div>
      <Link to="/devices" className="item">
        <img className="think logo" alt="Thnik logo" src="/logo-small.png" />
      </Link>
      <div className="right menu">
        <Link to="/devices" className="item">
          <i className="ui user icon" />
          {auth.currentUser().name}
        </Link>
      </div>
    </div>
  );
};

export default TopMenu;
