import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import { Button, Input } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { DateTime } from 'luxon';

import api from '../../api/api';
import Layout from './Layout';
import SemanticField from '../elements/SemanticField';
import { getMessage } from '../../api/errors';
import ForgotPasswordCode from './ForgotPasswordCode';

const ForgotPassword = () => {
  const [sending, setSending] = useState(false);
  const [validationId, setValidationId] = useState(null);
  const [codeTimeout, setCodeTimeout] = useState(null);
  const [error, setError] = useState(null);
  const initialValues = {
    email: '',
  };

  const handleSubmit = async (values) => {
    setSending(true);
    try {
      const { data: response } = await api.post('auth/password/forgot', values);
      const expireDate = DateTime.fromISO(response.expiresAt);
      const duration = expireDate.diff(DateTime.now(), 'seconds').toObject();
      const timeout = Math.round(duration.seconds);
      setCodeTimeout(timeout);
      setValidationId(response.id);
    } catch (e) {
      setError(getMessage(e.response.data));
    } finally {
      setSending(false);
    }
  };

  const renderError = (msg) => {
    return msg ? (
      <div className="ui negative message">
        <p>{msg}</p>
      </div>
    ) : '';
  };

  const handleClickBack = () => {
    setValidationId(null);
    setError(null);
  };

  const renderForm = () => {
    return (
      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        <Form className={`ui large form ${sending ? 'loading' : ''}`}>
          <div className="field">
            <SemanticField
              name="email"
              component={Input}
              icon="user"
              iconPosition="left"
              required
              placeholder="E-mail"
              size="big"
            />
          </div>
          { renderError(error) }
          <div className="field">
            <Button
              content="Recuperar senha"
              primary
              fluid
              size="huge"
            />
          </div>
          <div className="field">
            <Link to="/login">Voltar</Link>
          </div>
        </Form>
      </Formik>
    );
  };

  return (
    <Layout>
      {
        validationId
          ? (
            <ForgotPasswordCode
              validationId={validationId}
              timeout={codeTimeout}
              onClickBack={handleClickBack}
            />
          )
          : renderForm()
      }
    </Layout>
  );
};

export default ForgotPassword;
