import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import { Button } from 'semantic-ui-react';

import api from '../../api/api';
import { getMessage } from '../../api/errors';
import PasswordField from '../login/PasswordField';

const PasswordChange = () => {
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [sending, setSending] = useState(false);
  const initialValues = {
    oldPassword: '',
    newPassword: '',
  };

  const handleSubmit = async (values, { resetForm }) => {
    setSending(true);
    try {
      await api.post('auth/password/change', values);
      setSuccess(true);
      setError(null);
      resetForm();
    } catch (e) {
      setSuccess(false);
      setError(getMessage(e.response.data));
    } finally {
      setSending(false);
    }
  };

  const renderError = (msg) => {
    return msg ? (
      <div className="ui negative message">
        <p>{msg}</p>
      </div>
    ) : '';
  };

  const renderSuccess = () => {
    return success ? (
      <div className="ui positive message">
        <p>Senha alterada!</p>
      </div>
    ) : '';
  };

  return (
    <>
      <h2 className="ui header" style={{ textAlign: 'center' }}>
        Alterar senha
      </h2>
      <div className="layout-login">
        <div className="ui middle aligned center aligned grid">
          <div className="column">
            <div className="ui clearing segment">
              <Formik initialValues={initialValues} onSubmit={handleSubmit}>
                <Form className={`ui large form ${sending ? 'loading' : ''}`}>
                  <p>
                    Informe primeiro a senha atual e, depois,
                    a nova senha que será utilizada para acessar o sistema
                  </p>
                  <div className="field">
                    <label htmlFor="action">Senha atual</label>
                    <PasswordField name="oldPassword" placeholder="Senha atual" showLeftIcon={false} />
                  </div>
                  <div className="field">
                    <label htmlFor="action">Nova senha</label>
                    <PasswordField name="newPassword" placeholder="Nova senha" showLeftIcon={false} />
                  </div>
                  { renderError(error) }
                  { renderSuccess() }
                  <Button
                    type="submit"
                    content="Alterar senha"
                    primary
                    fluid
                    size="huge"
                  />
                </Form>
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PasswordChange;
