import React from 'react';
import { useHistory } from 'react-router-dom';

import auth from '../../services/auth';
import CodeValidation from './CodeValidation';

const ForgotPasswordCode = ({ validationId, timeout, onClickBack }) => {
  const history = useHistory();
  const handleSubmit = async (code) => {
    await auth.verifyCode(validationId, code);
    history.push('/login/password/reset');
  };

  return (
    <CodeValidation
      timeout={timeout}
      onClickBack={onClickBack}
      onSubmit={handleSubmit}
    />
  );
};

export default ForgotPasswordCode;
