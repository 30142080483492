import { addDays, getUnixTime } from 'date-fns';
import { React, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Grid, Modal, Button } from 'semantic-ui-react';

import api from '../../api/api';
import CardEvent from './CardEvent';
import DateSelector from './TimeFilter';
import './LogsDashboard.less';

export default function LogsDashboard() {
  const { physicalId } = useParams();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [openCsv, setOpenCsv] = useState(false);
  const [logs, setLogs] = useState([]);
  const [search, setSearch] = useState('');
  const [id, setId] = useState('');
  const defaultSelected = {
    from: new Date(),
    to: addDays(new Date(), 4),
  };
  const [range, setRange] = useState(defaultSelected);

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      const { data } = await api.post('logs', { physicalId });
      setLoading(false);
      setLogs(data.data);
    }
    fetchData();
  }, [physicalId]);

  const submit = (event) => {
    event.preventDefault();
    window.location.href = `/logs/${search}`;
  };

  // const submitTime = async () => {
  //   // window.location.href = `/logs/${teste}/${teste1}`;
  // };

  const filterLogs = async () => {
    setOpen(false);
    range.from = getUnixTime(range.from);
    range.to = getUnixTime(range.to);

    api.post('/logs/datePicker', { range }).then(({ data }) => {
      setLogs(data.data);
    });
    // setLogs(data.data);
  };

  const handleEnter = async (event) => {
    if (event.key === 'Enter') {
      submit(event);
    }
  };

  const csvDownload = async () => {
    try {
      api({
        method: 'GET',
        responseType: 'blob', // important
        url: `/logs/download/${id}/${range.from}/${range.to}`,
      }).then(
        (response) => {
          const temp = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = temp;
          link.setAttribute('download', 'file.csv');
          link.download = 'file.csv';
          document.body.appendChild(link);
          link.click();
        },
      );
    } catch (err) {
      console.warn(err);
    }
    // const { data } = await api.get(`/download/${id}`);
  //  return data;
  };

  return (
    <>
      <h2 className="ui header">
        <Link to="/logs">
          <span>Log de eventos</span>
        </Link>
        <span>
          <div
            className="sideView"
          >
            <button type="submit" className="calendar" onClick={() => setOpen(true)}>
              <i className="calendar alternate icon" />
            </button>

            <div className="">
              <input
                className="search-custom "
                type="text"
                defaultValue={search}
                onChange={(e) => setSearch(e.target.value)}
                placeholder="Serial Number"
                onKeyPress={handleEnter}
              />
            </div>
          </div>

        </span>
      </h2>

      {
        open ? (
          <Modal
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            open={open}
            size="mini"
            style={{ boxSizing: 'content-box', paddingRight: 175, alignItems: 'center' }}
          >
            <Modal.Header style={{ marginLeft: 150 }}>Horário do dispositivo</Modal.Header>
            <Modal.Content style={{ marginLeft: 80 }}>
              <Modal.Description>
                <div style={{ display: 'flex',
                  justifyContent: 'space-between' }}
                >
                  <input
                    className="search-custom "
                    type="text"
                    defaultValue={id}
                    onChange={(e) => setId(e.target.value)}
                    placeholder="Serial Number"
                  />
                  {
                    id ? (
                      <button type="button" style={{ backgroundColor: '#fff', border: 'none' }} onClick={() => csvDownload()}>
                        <i className="file icon" style={{ }} />
                      </button>
                    ) : null
                  }

                </div>
                <div>
                  <DateSelector range={range} setRange={setRange} />
                </div>
              </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
              <Button onClick={() => setOpen(false)} style={{ marginLeft: 150 }}>
                Cancelar
              </Button>
              <Button onClick={() => filterLogs()} style={{ backgroundColor: '#1560BD', color: '#fff' }}>
                Enviar
              </Button>
            </Modal.Actions>
          </Modal>
        ) : null
      }
      {
        openCsv ? (
          <Modal
            onClose={() => setOpenCsv(false)}
            onOpen={() => setOpenCsv(true)}
            open={openCsv}
            size="mini"
            style={{ boxSizing: 'content-box', paddingRight: 175, alignItems: 'center' }}
          >
            <Modal.Header style={{ marginLeft: 150 }}>
              Digite o physical Id do dispositivo
            </Modal.Header>
            <Modal.Content style={{ marginLeft: 80 }}>
              <Modal.Description>
                <div style={{ zIndex: 0 }}>
                  <input
                    className="search-custom "
                    type="text"
                    defaultValue={id}
                    onChange={(e) => setId(e.target.value)}
                    placeholder="Serial Number"
                  />
                </div>
              </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
              <Button onClick={() => setOpenCsv(false)} style={{ marginLeft: 150 }}>
                Cancelar
              </Button>
              <Button type="button" onClick={(e) => csvDownload(e)} style={{ backgroundColor: '#1560BD', color: '#fff' }}>
                Enviar
              </Button>
            </Modal.Actions>
          </Modal>
        ) : null
      }

      <div style={{ marginTop: 100, width: '75%', display: 'block', marginLeft: 'auto', marginRight: 'auto' }}>
        <Grid columns={1} divided className="ui centered grid">
          {!loading && logs?.map((log) => (
            <Grid.Row key={log._id} className="centered row">
              <Grid.Column className="centered column">
                <CardEvent key={log._id} data={log} />
              </Grid.Column>
            </Grid.Row>
          ))}
        </Grid>
      </div>
    </>
  );
}
