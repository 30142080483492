import { DateTime } from 'luxon';

export function getInformationsTable(device) {
  const calcUptime = () => {
    const lastBoot = DateTime.fromMillis(device.info.uptime);
    const { days, hours, minutes } = DateTime.now().diff(lastBoot, ['days', 'hours', 'minutes']).toObject();
    const sHours = String(hours).padStart(2, '0');
    const sMinutes = String(Math.round(minutes)).padStart(2, '0');
    return `${days} dias ${sHours}:${sMinutes}`;
  };
  return [
    {
      titulo: 'Informações técnicas',
      infosLinhas: [
        {
          label: 'Modelo do dispositivo',
          value: device.model.label,
        },
        {
          label: 'Número de série',
          value: device.physicalId,
        },
        {
          label: 'Versão do hardware',
          value: device.info.hardwareVersion,
        },
        {
          label: 'Versão do firmware',
          value: device.info.firmwareVersion,
        },
        {
          label: 'Uptime',
          value: calcUptime(),
        },
      ],
    },
    {
      titulo: 'Wi-Fi',
      infosLinhas: [
        {
          label: 'IP',
          value: device.info.ipWifi,
        },
        {
          label: 'IP do Gateway',
          value: device.info.ipGatewayWifi,
        },
        {
          label: 'Máscara de subrede',
          value: device.info.ipNetmaskWifi,
        },
        {
          label: 'IP do DNS',
          value: device.info.ipDnsWifi,
        },
        {
          label: 'Endereço MAC',
          value: device.info.macWifi,
        },
      ],
    },
    {
      titulo: 'Ethernet',
      infosLinhas: [
        {
          label: 'IP',
          value: device.info.ipEthernet,
        },
        {
          label: 'IP do Gateway',
          value: device.info.ipGatewayEthernet,
        },
        {
          label: 'Máscara de subrede',
          value: device.info.ipNetmaskEthernet,
        },
        {
          label: 'IP do DNS',
          value: device.info.ipDnsEthernet,
        },
        {
          label: 'Endereço MAC',
          value: device.info.macEthernet,
        },
      ],
    },
  ];
}
