import { DateTime } from 'luxon';

const horasMinutos = 'HH:mm';
const horasMinutoDiasMes = 'HH:mm dd/MM';

function defineShowManyPoints7d(paramTela) {
  if (paramTela <= 576) {
    return 8;
  }
  if (paramTela <= 768) {
    return 6;
  }
  return 5;
}
function defineShowManyPointsCustom(insideTheDay, paramTela) {
  if (insideTheDay) {
    return paramTela <= 576 ? 3 : 2;
  }
  return paramTela <= 576 ? 5 : 4;
}
export const timeboxOptions = {
  '2h': {
    tickLabel: (measuredAt) => {
      const date = DateTime.fromISO(measuredAt);
      if (date.minute % 5 === 0) {
        return date.toFormat(horasMinutos);
      }
      return null;
    },
    tooltipTitle: (datetime) => {
      return DateTime.fromISO(datetime).toFormat(horasMinutos);
    },
  },
  '24h': {
    tickLabel: (measuredAt, index, options) => {
      const { screenWidth } = options;
      const showManyPoints = screenWidth <= 768 ? 5 : 4;
      return index % showManyPoints === 0 ? DateTime
        .fromISO(measuredAt).toFormat(horasMinutos) : null;
    },
    tooltipTitle: (datetime) => {
      const start = datetime.toFormat(horasMinutos);
      const end = datetime.plus({ minute: 10 }).toFormat(horasMinutos);
      return `${start} - ${end}`;
    },
  },
  '7d': {
    tickLabel: (measuredAt, index, options) => {
      const { screenWidth } = options;
      const date = DateTime.fromISO(measuredAt);
      const showManyPoints = defineShowManyPoints7d(screenWidth);
      return index % showManyPoints === 0 ? date.toFormat(horasMinutoDiasMes) : null;
    },
    tooltipTitle: (datetime) => {
      return datetime.toFormat(horasMinutoDiasMes);
    },
  },
  custom: {
    tickLabel: (measuredAt, index, options) => {
      const { insideDay, screenWidth } = options;
      const showManyPoints = defineShowManyPointsCustom(insideDay, screenWidth);
      const date = insideDay ? DateTime.fromISO(measuredAt).toFormat(horasMinutos)
        : DateTime.fromISO(measuredAt).toFormat(horasMinutoDiasMes);
      return index % showManyPoints === 0 ? date : null;
    },
    tooltipTitle: (datetime) => {
      return DateTime.fromISO(datetime).toFormat(horasMinutoDiasMes);
    },
  },
};
