import React from 'react';

import './Login.less';

const Layout = ({ children }) => {
  return (
    <div className="layout-login">
      <div className="ui middle aligned center aligned grid">
        <div className="column">
          <div className="ui clearing segment">
            <h2 className="ui image header">
              <img alt="Thnik logo" src="/logo-small.png" className="think logo" />
            </h2>
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Layout;
