import React, { useEffect, useState } from 'react';

import api from '../../../api/api';
import DeviceCard from '../card/DeviceCard';
import { RenderLoading } from '../../renders/RenderLoading';
import { RenderNoResults } from '../../renders/RenderNoResults';

const renderDevices = (devices) => devices
  .map((device) => <DeviceCard key={device._id} device={device} />);

const DeviceListShared = () => {
  const [devices, setDevices] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      const { data } = await api.get('devices/shared');
      setLoading(false);
      setDevices(data);
    }
    fetchData();
  }, []);

  return (
    <>
      <h2 className="ui header">Dispositivos compartilhados</h2>
      <div className="ui device cards">
        {devices.length || loading ? renderDevices(devices)
          : (
            <RenderNoResults
              messageHeader="Nenhum dispositivo compartilhado com você"
              messageParagraph="Os dispositivos que você não cadastrou, mas teve o acesso concedido, irão aparecer aqui."
            />
          )}
        {loading ? <RenderLoading /> : null}
      </div>
    </>
  );
};

export default DeviceListShared;
