import React, { useState } from 'react';
import { Modal, Button, Select, Dropdown, Message } from 'semantic-ui-react';
import { Formik, Field, Form } from 'formik';
import { DateTime } from 'luxon';

import SemanticField from '../../elements/SemanticField';
import api from '../../../api/api';
import { getMessage } from '../../../api/errors';

const weekdays = [
  { key: 1, text: 'Segunda', value: 1 },
  { key: 2, text: 'Terça', value: 2 },
  { key: 3, text: 'Quarta', value: 3 },
  { key: 4, text: 'Quinta', value: 4 },
  { key: 5, text: 'Sexta', value: 5 },
  { key: 6, text: 'Sábado', value: 6 },
  { key: 7, text: 'Domingo', value: 7 },
];

const ScheduleForm = ({ deviceId, actions, onSave, onDelete, schedule, children, offline }) => {
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(null);
  const [sending, setSending] = useState(false);
  const editMode = schedule;
  const initialValues = {
    weekday: schedule?.weekday,
    time: schedule?.time || '',
    action: schedule?.action || '',
    timezone: DateTime.local().zoneName,
  };

  const fillSchedules = (newSchedule, action) => {
    return [{ ...newSchedule, action }];
  };

  const create = async (values) => {
    const action = actions.find((a) => a._id === values.action);
    const { data: newSchedule } = await api.post(`devices/${deviceId}/schedule`, { ...values, actionName: action.name });
    onSave(fillSchedules(newSchedule, action));
  };

  const update = async (values) => {
    const action = actions.find((a) => a._id === values.action);
    const { data: newSchedule } = await api.put(`devices/${deviceId}/schedule/${schedule._id}`, { ...values, actionName: action.name });
    onSave(fillSchedules(newSchedule, action), schedule._id);
  };

  const handleRemove = async () => {
    setSending(true);
    try {
      await api.delete(`devices/${deviceId}/schedule/${schedule._id}`);
      onDelete(schedule._id);
    } catch {
      setError(null);
      setSending(false);
    }
  };

  const handleSubmit = async (values) => {
    setSending(true);
    setError(null);
    try {
      if (editMode) {
        await update(values);
      } else {
        await create(values);
      }
      setOpen(false);
    } catch (e) {
      setError(getMessage(e?.response?.data));
    } finally {
      setSending(false);
    }
  };

  return (
    <Modal
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      size="mini"
      trigger={children}
    >
      <Modal.Header>Agendar uma açao</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <Formik initialValues={initialValues} onSubmit={handleSubmit}>
            <Form id="form-id" className={`ui form ${sending ? 'loading' : ''}`}>
              <div className="field">
                <label htmlFor="weekday">Dia da semana</label>
                <SemanticField
                  name="weekday"
                  component={Dropdown}
                  fluid
                  selection
                  required
                  placeholder="Selecione um dia"
                  options={weekdays}
                />
              </div>
              <div className="field">
                <label htmlFor="time">Horário</label>
                <Field name="time" type="time" placeholder="HH:mm" />
              </div>
              <div className="field">
                <label htmlFor="action">Ação</label>
                <SemanticField
                  name="action"
                  component={Select}
                  placeholder="Selecione uma ação"
                  options={actions.map((action) => {
                    return { key: action._id, text: action.label, value: action._id };
                  })}
                />
              </div>
              {
                error
                  ? (
                    <Message
                      onDismiss={() => setError(null)}
                      header="Não foi possível agendar esta ação."
                      content={error}
                      color="red"
                    />
                  )
                  : ''
              }
            </Form>
          </Formik>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        {
          editMode ? (
            <Button basic color="red" onClick={handleRemove} disabled={offline}>
              Remover
            </Button>
          ) : (
            <Button onClick={() => setOpen(false)}>
              Cancelar
            </Button>
          )
        }
        <Button
          content="Salvar"
          labelPosition="right"
          icon="checkmark"
          form="form-id"
          positive
          disabled={offline}
        />
      </Modal.Actions>
    </Modal>
  );
};

export default ScheduleForm;
