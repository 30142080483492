import React from 'react';
import { RenderChildren } from '../RenderChildren/RenderChildren';
import { RenderSensors } from '../RenderSensors/RenderSensors';

export function RenderDetails({ device, measures, dateOffline, skills }) {
  const hasTriggerSkill = () => {
    if (skills?.length) {
      return skills.find((skill) => skill === 'trigger');
    }
    return false;
  };
  return (
    <>
      <div className="device-sensors">
        <div className="ui grid">
          <div className="row">
            <RenderSensors
              id={device._id}
              hasTrigger={hasTriggerSkill()}
              measurements={measures}
              sensorsModel={device.model.sensors}
            />
          </div>
        </div>
      </div>
      {
        device.model.childrenModels?.length ? (
          <RenderChildren
            label={device.model.childrenLabel}
            device={device}
            offline={dateOffline}
          />
        )
          : ''
      }
    </>
  );
}
