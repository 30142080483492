import { DateTime } from 'luxon';
import React, { useState, useEffect } from 'react';
import { Select } from 'semantic-ui-react';

import api from '../../../../api/api';
import Breadcrumb from '../Breadcrumb';
import RealtimeChart from '../RealtimeChart';
import SensorChart from '../SensorChart';
import SensorDateRannge from '../SensorDateRange';
import './SensorDetails.less';

const SensorDetails = ({ match }) => {
  const { deviceId, sensorName } = match.params;
  const [sensor, setSensor] = useState({});
  const [sensorModel, setSensorModel] = useState({});
  const [timebox, setTimebox] = useState({ type: 'realtime' });
  const [showCustomDates, setShowCustomDates] = useState(false);

  const timeboxOptions = [
    { key: 'realtime', value: 'realtime', text: 'Em tempo real' },
    { key: '2h', value: '2h', text: 'Últimas 2h' },
    { key: '24h', value: '24h', text: 'Últimas 24h' },
    { key: '7d', value: '7d', text: 'Últimos 7 dias' },
    { key: 'custom', value: 'custom', text: 'Período customizado' },
  ];

  useEffect(() => {
    async function fetchData() {
      const { data } = await api.get(`/sensors/${deviceId}/${sensorName}`);
      setSensor(data.sensor);
      setSensorModel(data.model);
    }

    fetchData();
  }, []);

  const handleTimeboxChange = (event, data) => {
    if (data.value === 'custom') {
      setShowCustomDates(true);
    } else {
      setTimebox({ type: data.value });
    }
  };

  const handleDateRange = (range) => {
    setTimebox({ type: 'custom', range });
  };

  const handleCloseRange = () => {
    setShowCustomDates(false);
  };

  const renderRange = () => {
    const from = DateTime.fromISO(timebox.range.from).toFormat('dd/MM/yyyy HH:mm');
    const to = DateTime.fromISO(timebox.range.to).toFormat('dd/MM/yyyy HH:mm');
    return (
      <>
        <span style={{ margin: '0 16px', fontSize: '1.1em' }}>
          De:&nbsp;
          {`${from}`}
        </span>
        <span style={{ margin: '0 16px', fontSize: '1.1em' }} className="span">a</span>
        <span style={{ margin: '0 16px', fontSize: '1.1em' }}>{`${to}`}</span>
        <button className="ui icon button button-edit" type="button" onClick={() => setShowCustomDates(true)}>
          <i className="edit icon" />
        </button>
      </>
    );
  };

  return (
    <div>
      <Breadcrumb deviceId={deviceId} sensor={sensor} />
      <h2 className="ui header">
        {sensor.name}
      </h2>
      <div className="ui raised segment chidren-devices">
        <div className="flex center column">
          <Select options={timeboxOptions} value={timebox.type} onChange={handleTimeboxChange} />
          {showCustomDates ? <SensorDateRannge show={showCustomDates} range={timebox.range} onConfirm={handleDateRange} onClose={handleCloseRange} /> : ''}
          {timebox.range ? renderRange() : ''}
        </div>
        { sensor._id && timebox.type !== 'realtime' ? <SensorChart deviceId={deviceId} sensor={sensor} timebox={timebox} model={sensorModel} /> : ''}
        { sensor._id && timebox.type === 'realtime' ? <RealtimeChart deviceId={deviceId} sensor={sensor} model={sensorModel} /> : ''}
      </div>
      <br />
      {/* <p>
        Permitir customizar alertas (notificação) ou ações (desligar, reiniciar...)
        de acordo com o valor do sensor. Ex.: Caso a temperatura chege a 30 graus,
        reiniciar dispositivo
      </p> */}
    </div>
  );
};

export default SensorDetails;
