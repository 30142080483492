import React from 'react';
import { getInformationsTable } from './getInformationsTable';
import { TableInfoDevice } from './TableInfoDevice';
import './DeviceInfo.less';

const DeviceInfo = ({ device }) => {
  const tables = getInformationsTable(device);
  return (
    <div className="div-infos-tables">
      {
        tables.map((table) => <TableInfoDevice key={table.titulo} infoTable={table} />)
      }
    </div>
  );
};

export default DeviceInfo;
