import React, { useEffect, useState } from 'react';
import { useParams, useHistory, Link } from 'react-router-dom';
import { Button } from 'semantic-ui-react';
import { Formik, Field, Form } from 'formik';

import api from '../../../api/api';
import { getMessage } from '../../../api/errors';
import { refreshUserDevices } from '../../../api/socket';

const DeviceNew = () => {
  const { serialNumberParam } = useParams();
  const [sending, setSending] = useState(false);
  const [error, setError] = useState(null);
  const history = useHistory();
  const initialValues = {
    serialNumber: serialNumberParam || '',
  };

  async function validateDevice(serialNumber) {
    try {
      setSending(true);
      const { data: deviceId } = await api.post('devices/validate', { serialNumber });
      refreshUserDevices();
      setSending(false);
      history.push(`/devices/${deviceId}`);
    } catch (e) {
      setSending(false);
      setError(e.response?.data);
    }
  }

  useEffect(() => {
    // Caso já tenha o número de série na url, já tenta validar
    if (serialNumberParam) validateDevice(serialNumberParam);
  }, []);

  const handleSubmit = async (values) => {
    validateDevice(values.serialNumber);
  };

  const renderError = () => {
    return error ? (
      <div className="ui negative message">
        <div className="header">
          Não foi possível cadastrar o dispositivo
        </div>
        <p>
          {getMessage(error)}
        </p>
      </div>
    ) : '';
  };

  return (
    <>
      <h2 className="ui header">Cadastrar Dispositivo</h2>

      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        <Form className={`ui form ${sending ? 'loading' : ''}`}>
          <div className="field">
            <label htmlFor="serialNumber">Número de série</label>
            <Field
              name="serialNumber"
              required
              placeholder="Informe o número de série do dispositivo"
            />
          </div>
          {renderError()}
          <Button
            content={sending ? 'Conectando-se ao dispositivo...' : 'Cadastrar'}
            labelPosition="right"
            icon="checkmark"
            positive
          />
          <Button
            as={Link}
            to="/devices"
            content="Cancelar"
          />
        </Form>
      </Formik>
    </>
  );
};

export default DeviceNew;
