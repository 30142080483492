import React from 'react';

export function RenderLoading() {
  return (
    <div className="ui device card loading">
      <div className="content">
        <div className="ui inverted dimmer active">
          <div className="ui text loader">Carregando...</div>
        </div>
      </div>
    </div>
  );
}
