/* eslint-disable func-names */
import React, { useEffect, useState } from 'react';
import api from '../../../../api/api';
import { addSensorsListener, stopSensorsListener, addEventListener, stopEventListener, addAlertListener, stopAlertListener } from '../../../../api/socket';
import EditableLabel from '../../../elements/EditableLabel/EditableLabel';
import './DeviceDetails.less';
import DeviceToken from '../DeviceToken/DeviceToken';
import Breadcrumb from '../Breadcrumb';
import DeviceInfo from '../DeviceInfo/DeviceInfo';
import FirmwareUpdater from '../FirmwareUpdater';
import { RenderLoading } from '../../../renders/RenderLoading';
import { RenderOffline } from './RenderOffiline/RenderOffiline';
import { RenderSkills } from './RenderSkills/RenderSkills';
import { RenderDetails } from './RenderDetails/RenderDetails';
import { Alert } from './AlertComponent/Alert';

const DeviceDetails = ({ match }) => {
  const { id } = match.params;
  const [device, setDevice] = useState(null);
  const [measures, setMeasures] = useState([]);
  const [offlineSince, setOfflineSince] = useState(null);
  const [skills, setSkills] = useState([]);
  const [alerts, setAlerts] = useState([]);

  useEffect(() => {
    async function fetchData() {
      api.get(`devices/${id}`).then(({ data }) => {
        setDevice(data);
      });
      // buscando os nomes das skills
      const { data } = await api.get(`devices/${id}/skills`);
      setSkills(data);
    }
    fetchData();
  }, [id]);

  useEffect(() => {
    if (!device) return () => { };
    setMeasures(device.sensors || []);
    setOfflineSince(device.rootDevice?.offlineSince || device.offlineSince);
    addSensorsListener(device.physicalId, (changes) => {
      setMeasures(changes);
    });
    addEventListener(device.rootDevice?.physicalId || device.physicalId, 'offline', setOfflineSince);
    addAlertListener(device.rootDevice?.physicalId || device.physicalId, (alert) => {
      setAlerts((oldAlerts) => {
        // if don't have any alerts, add
        if (oldAlerts.length === 0) {
          return [{ ...alert, timestamp: Date.now() }];
        }

        // adds a new alert
        if (oldAlerts.length > 0
          && !oldAlerts.find((it) => it.childId === alert.childId)) {
          const newAlerts = [...oldAlerts,
            { ...alert, timestamp: Date.now() }];
          return newAlerts;
        }

        // update an existing alert
        return oldAlerts.map((it) => {
          if (it.childId === alert.childId) {
            return { ...alert, timestamp: Date.now() };
          }
          return it;
        });
      });
    });

    return () => {
      if (device?.physicalId) {
        stopSensorsListener(device.physicalId);
        stopEventListener(device.rootDevice?.physicalId || device.physicalId, 'offline');
        stopAlertListener(device.rootDevice?.physicalId || device.physicalId);
      }
    };
  }, [device]);

  const changeDeviceName = (deviceName) => {
    if (deviceName === device.name) return;
    const oldDeviceName = device.name;
    setDevice({ ...device, name: deviceName });
    api
      .put(`devices/${id}`, { name: deviceName })
      .catch(() => {
        setDevice({ ...device, name: oldDeviceName });
        // eslint-disable-next-line no-alert
        window.alert('Erro ao alterar o nome do dispositivo!');
      });
  };

  const handleFirmwareUpdate = (newVersion) => {
    setDevice({ ...device, info: { ...device.info, firmwareVersion: newVersion } });
  };

  if (!device) return (<RenderLoading />);

  return (
    <div>
      <Breadcrumb device={device} />
      <h2 className="ui header">
        <EditableLabel value={device.name} onSubmit={changeDeviceName} />
      </h2>
      <RenderOffline dateOffline={offlineSince} />
      { alerts.length > 0 && alerts.map((alert) => {
        const deviceId = device.rootDevice?.physicalId || device.physicalId;
        const index = alerts.findIndex((it) => it.childId === alert.childId);
        return (
          <Alert key={index} alert={alert} device={deviceId} />
        );
      })}
      {!device.parent ? <FirmwareUpdater device={device} onUpdate={handleFirmwareUpdate} /> : null}
      <RenderDetails
        device={device}
        measures={measures}
        skills={skills}
        dateOffline={offlineSince}
      />
      <RenderSkills
        skills={skills}
        id={device._id}
        offline={offlineSince}
        physicalId={device.physicalId}
        actions={device.model?.actions}
      />
      {device.token ? <DeviceToken device={device} /> : null}
      {(device.info && !device.parent) ? <DeviceInfo device={device} /> : null}
    </div>
  );
};

export default DeviceDetails;
