import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import { Button } from 'semantic-ui-react';
import { useHistory } from 'react-router-dom';

import Layout from './Layout';
import auth from '../../services/auth';
import { getMessage } from '../../api/errors';
import PasswordField from './PasswordField';

const PasswordReset = () => {
  const history = useHistory();
  const [error, setError] = useState(null);
  const [sending, setSending] = useState(false);
  const initialValues = {
    password: '',
  };

  const handleSubmit = async (values) => {
    setSending(true);
    try {
      const pathAfterLogin = await auth.resetPassword(values.password);
      history.push(pathAfterLogin);
    } catch (e) {
      setError(getMessage(e.response.data));
    } finally {
      setSending(false);
    }
  };

  const renderError = (msg) => {
    return msg ? (
      <div className="ui negative message">
        <p>{msg}</p>
      </div>
    ) : '';
  };

  return (
    <Layout>
      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        <Form className={`ui large form ${sending ? 'loading' : ''}`}>
          <p>
            Informe a nova senha que será utilizada para acessar o sistema
          </p>
          <div className="field">
            <PasswordField />
          </div>
          { renderError(error) }
          <Button
            type="submit"
            content="Salvar"
            primary
            fluid
            size="huge"
          />
        </Form>
      </Formik>
    </Layout>
  );
};

export default PasswordReset;
