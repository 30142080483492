import React, { useEffect, useState } from 'react';
import DeviceChildItem from './DeviceChildItem/DeviceChildItem';

import api from '../../../api/api';

const DeviceChildrenList = ({ device, offline }) => {
  const [children, setChildren] = useState([]);

  const renderChildren = () => {
    const types = new Set(
      children.map((child) => {
        return child.model.name.split('-')[0];
      }),
    );

    const quantityTypes = {};
    types.forEach((type) => {
      quantityTypes[type] = 0;
    });

    return children.map((child) => {
      const type = child.model.name.split('-')[0];
      quantityTypes[type] += 1;
      return (
        <DeviceChildItem
          key={child._id}
          child={child}
          newIndex={quantityTypes[type]}
          offline={offline}
        />
      );
    });
  };

  useEffect(() => {
    async function fetchData() {
      const { data } = await api.get(`devices/${device._id}/children`);
      setChildren(data);
    }
    fetchData();
  }, []);

  return (
    <div className={`ui raised segment chidren-devices ${children.length ? '' : 'loading'}`}>
      <div className="ui divided unstackable items">
        {renderChildren()}
      </div>
    </div>
  );
};

export default DeviceChildrenList;
