import api from '../../../../api/api';

export async function sendSignal({ id, idControl, button, changeLoading, changeError }) {
  changeLoading(true);
  try {
    await api.post(`/devices/${id}/ir/send/${idControl}`, {
      button,
    });
    changeError('');
  } catch (error) {
    changeError(error.response.data.error);
  }
  changeLoading(false);
}
