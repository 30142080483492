import React from 'react';
import { Menu, Sidebar } from 'semantic-ui-react';
import SidebarMenu from './SidebarMenu';

const SidebarMobile = ({ visible, onHide }) => (
  // <div className="ui vertical inverted blue left sidebar menu">
  <Sidebar
    as={Menu}
    animation="overlay"
    inverted
    className="blue think"
    onHide={onHide}
    vertical
    // visible={false}
    visible={visible}
  >
    <SidebarMenu onClick={onHide} />
  </Sidebar>
);

export default SidebarMobile;
