import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Progress } from 'semantic-ui-react';
import Sensor from '../sensor/Sensor';
import { addSensorsListener, stopSensorsListener } from '../../../api/socket';
import './DeviceCard.less';

const DeviceCard = ({ device }) => {
  const [measures, setMeasures] = useState([]);

  useEffect(() => {
    setMeasures(device.sensors || []);
    addSensorsListener(device.physicalId, (changes) => {
      setMeasures(changes);
    });
    return () => {
      stopSensorsListener(device.physicalId);
    };
  }, []);

  const renderSensors = (sensors, measurements) => {
    return measurements.slice(0, 2).map((sensor) => (
      <div key={sensor._id} className="column center aligned">
        <Sensor value={sensor.value} sensor={sensor} />
      </div>
    ));
  };

  function quantidade(value, max) {
    return (
      <>
        <Progress percent={(value / max) * 100} color="blue" />
        <div className="value sensor">{ `${value}/${max}` }</div>
      </>
    );
  }

  return (
    <div className="ui device card">
      <div className="content">
        <div className="center aligned">
          <img className="icon " alt="Ícone representando o dispositivo" src="/filtro-icone.png" />
        </div>
        <div className="center aligned title">{device.name}</div>
        <div className="center aligned subtitle">
          {device.model.name}
        </div>
        <div className="ui equal width grid">
          <div className="column center aligned">
            {quantidade(device.children.turnedOn, device.children.total)}
          </div>
          {renderSensors(device.model.sensors, measures)}
        </div>
      </div>
      <div className="extra content center aligned">
        <Link className="ui basic button" to={`/devices/${device._id}`}>Detalhes</Link>
      </div>
    </div>
  );
};

export default DeviceCard;
