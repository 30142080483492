import { DateTime } from 'luxon';
import React from 'react';
import { Message } from 'semantic-ui-react';
import api from '../../../../../api/api';

const alertDictionary = {
  current: 'Corrente',
  voltage: 'Tensão',
  power: 'Potência',
  temperature: 'Temperatura',
  pressure: 'Pressão',
  turnedOn: 'Sensor Digital',
};

export function Alert({ alert, device }) {
  if (!alert && !device) return '';

  const [deviceData, setDeviceData] = React.useState({});
  const [alertData] = React.useState(alert);
  const [deviceInfo] = React.useState(device);
  const [visible, setVisible] = React.useState(true);
  React.useEffect(() => {
    async function fetchData() {
      const deviceId = (() => {
        if (alertData.childId !== '-1') {
          return `${deviceInfo}.${alertData.childId}`;
        }
        return deviceInfo;
      })();

      api.get(`devices/physical/${deviceId}`).then(({ data }) => {
        setDeviceData(data);
      });
    }
    fetchData();
  }, []);
  return (
    <>
      { visible && (
        <>
          <Message
            onDismiss={() => setVisible(false)}
            header={`Alerta: O dispositivo ${deviceData.name} está com problemas, Horario: ${
              DateTime.fromISO(DateTime.now()).toFormat('dd/MM/yyyy HH:mm')} `}
            item="teste"
            content={`Verifique os seguintes sensores: ${Object.keys(alertData).map((key) => {
              if (key in alertDictionary) {
                return `${alertDictionary[key]}, `;
              }
              return '';
            }).join('').slice(0, -2)}`}
            warning
            icon="exclamation triangle"
          />
        </>
      )}
    </>
  );
}
