import React, { useEffect, useState, Suspense } from 'react';
import { Link } from 'react-router-dom';
import { Checkbox, Popup } from 'semantic-ui-react';
import api from '../../../../../api/api';
import EditableLabel from '../../../../elements/EditableLabel/EditableLabel';
import { popupStyle } from '../../PopupStyle';
import Sensor from '../../../sensor/Sensor';
import './RenderSensors.less';

export function RenderSensors({ sensorsModel, measurements, id, hasTrigger }) {
  const [sensors, setSensors] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState({});
  const [newThreshold, setNewThreshold] = useState(null);
  const [newTriggerDown, setNewTriggerDown] = useState(null);
  const [newTriggerUp, setNewTriggerUp] = useState(null);
  const [trigger, setTrigger] = useState(false);
  const [err, setErr] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (sensorsModel) {
      setSensors(measurements);
    }
  }, [measurements]);

  const updateSensorConfigRender = (sensor, threshold, { up, down }) => {
    setSensors(
      sensors.map((s) => {
        if (
          Number(s.sensorId) === Number(sensor.sensorId)
          && s.sensorType === sensor.sensorType
        ) {
          return {
            ...s,
            config: {
              threshold: threshold ?? modalData.config?.threshold,
              triggerDown: down ?? modalData.config?.triggerDown,
              triggerUp: up ?? modalData.config?.triggerUp,
            },
          };
        }
        return s;
      }),
    );
  };

  const handleUpdateSensorConfig = (sensor, threshold, { up, down }) => {
    if (
      (threshold !== null && threshold !== undefined)
      || (down !== null && down !== undefined)
      || (up !== null && up !== undefined)
      || trigger !== null
    ) {
      let upValue;
      let downValue;
      if (!up) {
        upValue = Number(modalData.config?.triggerUp);
      } else {
        upValue = Number(up);
      }
      if (!down) {
        downValue = Number(modalData.config?.triggerDown);
      } else {
        downValue = Number(down);
      }
      if (upValue < downValue || upValue - downValue < 10) {
        setErr(
          'O valor de disparo para cima deve\nser maior que o valor de disparo para baixo\n(Diferença mínima de 10)',
        );
        return;
      }
      setLoading(true);

      const newConfig = {
        threshold: threshold !== null || threshold !== undefined ? threshold : '**',
        triggerDown: downValue || '**',
        triggerUp: upValue || '**',
        trigger,
        hasTrigger,
      };

      updateSensorConfigRender(sensor, threshold, { upValue, downValue });

      api
        .post(
          `/devices/sensor/config/${id}/${sensor.sensorId}-${sensor.sensorType}`,
          newConfig,
        )
        .then(() => {
          setShowModal(false);
          if (upValue || downValue) {
            window.location.reload();
          }
        })
        .catch(() => {
          setErr('O dispositivo não está respondendo');
        })
        .finally(() => {
          setNewThreshold(null);
          setNewTriggerDown(null);
          setNewTriggerUp(null);
          setLoading(false);
        });
    }
  };

  return (
    <>
      {sensors
        && sensors.length > 0
        && sensors.map((sensor) => (
          <div
            key={`${sensor.sensorId}-${sensor.sensorType}`}
            className="eight wide mobile five wide tablet four wide computer column"
          >
            <div className="sensor-box">
              <div className="ui segment">
                <Link
                  to="#/"
                  onClick={() => {
                    setModalData(sensor);
                    setTrigger(sensor.config?.trigger);
                    setShowModal(true);
                  }}
                >
                  <Sensor value={sensor.value} sensor={sensor} />
                </Link>
                <div
                  className="label"
                  style={{
                    display: 'flex',
                  }}
                >
                  <EditableLabel
                    value={sensor.name}
                    onSubmit={(value) => {
                      api
                        .patch(`devices/${id}/sensors`, {
                          ...sensor,
                          name: value,
                        })
                        .catch(() => {
                          alert('Erro ao atualizar nome do sensor');
                        });
                      Object.assign(sensor, { ...sensor, name: value });
                    }}
                  />
                  <Link
                    to={`/devices/${id}/sensor/${sensor.sensorId}-${sensor.sensorType}`}
                    className="link"
                  >
                    <Popup
                      style={popupStyle}
                      content={`Gráfico de ${sensor.name}`}
                      trigger={<i className="chart bar icon" />}
                    />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        ))}

      {showModal && modalData ? (
        <div>
          <div className={`ui ${showModal ? 'active' : ''} mini modal`}>
            <a
              style={{
                display: 'flex',
                float: 'right',
                margin: 10,
                color: 'inherit',
              }}
              href="#/"
              onClick={() => {
                setShowModal(false);
              }}
            >
              <i className="close icon" />
            </a>
            <div className="header">
              Sensor:
              {' '}
              {modalData.name}
            </div>
            <div className="image content">
              <div className="description">
                {modalData.model.analogic ? (
                  <>
                    <h4>Configuração de alertas</h4>
                    <div>
                      Alertar a partir de:
                      {' '}
                      <EditableLabel
                        value={
                          newThreshold
                          || modalData.config?.threshold
                          || modalData.model.analogic.threshold
                        }
                        onSubmit={(value) => {
                          setNewThreshold(value);
                        }}
                      />
                    </div>
                    {hasTrigger
                    && `${modalData.sensorType}-${modalData.sensorId}`
                      === 'temperature-0' ? (
                        <>
                          <h4>Configurações de ativação do ar-condicionado</h4>
                          <div style={{ margin: 'auto' }}>
                            <Checkbox
                              toggle
                              className="green"
                              checked={trigger}
                              onClick={() => {
                                setTrigger(!trigger);
                                setNewTriggerDown(null);
                                setNewTriggerUp(null);
                              }}
                            />
                          </div>
                          {trigger ? (
                            <>
                              <div>
                                Ativar ventilação abaixo de:
                                {' '}
                                <EditableLabel
                                  value={
                                  newTriggerDown
                                  || modalData.config?.triggerDown
                                }
                                  onSubmit={(value) => {
                                    setNewTriggerDown(value);
                                  }}
                                />
                              </div>
                              <div>
                                Ativar resfriamento acima de:
                                {' '}
                                <EditableLabel
                                  value={
                                  newTriggerUp || modalData.config?.triggerUp
                                }
                                  onSubmit={(value) => {
                                    setNewTriggerUp(value);
                                  }}
                                />
                              </div>
                            </>
                          ) : null}
                        </>
                      ) : null}
                  </>
                ) : (
                  <>
                    <div>
                      <div className="inline fields">
                        <label>
                          Alertar quando
                          {' '}
                          {modalData.config?.threshold === 1 ? 'On' : 'Off'}
                        </label>
                        <div className="field">
                          <div className="ui radio checkbox">
                            <input
                              type="radio"
                              name="digital"
                              onChange={() => setNewThreshold(1)}
                            />
                            <label>On</label>
                          </div>
                        </div>
                        <div className="field">
                          <div className="ui radio checkbox">
                            <input
                              type="radio"
                              name="digital"
                              onChange={() => setNewThreshold(0)}
                            />
                            <label>Off</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}

                {loading ? (
                  <Suspense fallback={<div />}>
                    <div className="ui active centered inline loader" />
                  </Suspense>
                ) : null}

                {err ? (
                  <div
                    className="ui negative message"
                    style={{
                      whiteSpace: 'pre-wrap',
                    }}
                  >
                    <div className="header">
                      Erro ao atualizar configuração do sensor
                    </div>
                    <p>{err}</p>
                  </div>
                ) : null}
              </div>
            </div>
            <div className="actions">
              <button
                type="button"
                className="ui black deny button"
                onClick={() => {
                  setShowModal(false);
                }}
              >
                Cancelar
              </button>
              <button
                type="button"
                className="ui blue right labeled icon button"
                onClick={() => {
                  handleUpdateSensorConfig(modalData, newThreshold, {
                    up: newTriggerUp,
                    down: newTriggerDown,
                  });
                }}
              >
                Salvar
                <i className="checkmark icon" />
              </button>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
}
