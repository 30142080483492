/* eslint-disable object-curly-newline */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { Button } from 'semantic-ui-react';
import WatchdogForm from './WatchdogForm';
import { getMessage } from '../../../api/errors';

import api from '../../../api/api';
import { addEventListener, stopEventListener } from '../../../api/socket';

const Watchdog = ({ deviceId, physicalId, offline }) => {
  const [editMode, setEditMode] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [watchdog, setWatchdog] = useState(null);
  const [error, setError] = useState(null);
  const [sending, setSending] = useState(false);

  const handleSave = (newWatchdog) => {
    setWatchdog(newWatchdog);
    setDisabled(false);
    setEditMode(false);
  };

  const disableWatchdog = () => {
    setDisabled(true);
    setEditMode(false);
  };

  const handleDelete = async () => {
    try {
      setSending(true);
      await api.delete(`devices/${deviceId}/watchdog/${watchdog._id}`);
      disableWatchdog();
    } catch (e) {
      setError(e.response?.data);
    } finally {
      setSending(false);
    }
  };

  useEffect(() => {
    async function fetchData() {
      const { data } = await api.get(`devices/${deviceId}/watchdog`);
      setWatchdog(data);
    }

    fetchData();

    addEventListener(physicalId, 'wd/giveup', disableWatchdog);
    return stopEventListener(physicalId, 'wd/giveup', disableWatchdog);
  }, []);

  const renderError = () => {
    return error ? (
      <div className="ui negative message">
        <div className="header">
          Erro ao tentar desativar este watchdog.
        </div>
        <p>{getMessage(error)}</p>
      </div>
    ) : '';
  };

  const isActive = () => {
    return watchdog && !disabled && !watchdog.disabledAt;
  };

  const renderWatchdog = () => {
    return isActive()
      ? (
        <div>
          <h5>IP ou domínio:</h5>
          <h5>{watchdog.pingTo}</h5>
          {renderError()}
          <Button basic color="blue" onClick={() => setEditMode(true)} disabled={offline}>
            Editar
          </Button>
          <Button basic color="red" onClick={handleDelete} disabled={offline}>
            Remover
          </Button>
        </div>
      )
      : (
        <Button basic color="blue" onClick={() => setEditMode(true)} disabled={offline}>
          Ativar watchdog
        </Button>
      );
  };

  return (
    <div>
      <h2> Watchdog </h2>
      <div className={`ui raised segment ${sending ? 'loading' : ''}`}>
        {
          editMode
            ? (
              <WatchdogForm
                watchdog={watchdog}
                deviceId={deviceId}
                onSave={handleSave}
                onCancel={() => setEditMode(false)}
              />
            )
            : renderWatchdog()
        }
      </div>
    </div>
  );
};

export default Watchdog;
