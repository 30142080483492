import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Checkbox } from 'semantic-ui-react';
import './controls.less';
import { RenderLoading } from '../../../renders/RenderLoading';
import { ItemCustomControl } from './itemCustomControl';
import api from '../../../../api/api';

export function CustomControl() {
  const { id, idControl, edit } = useParams();
  const [active, setActive] = useState(edit === 'true');
  const [isLoading, setIsLoading] = useState(false);
  const [control, setControl] = useState({});
  const [changeName, setChangeName] = useState(false);
  let buttons = [];
  const [activeButtons, setActiveButtons] = useState([]);
  useEffect(() => {
    async function getControl() {
      setIsLoading(true);
      const { data } = await api.get(`/devices/${id}/ir/${idControl}`);
      setControl(data.irControl);
      Object.entries(data.irControl?.buttons)
        .forEach(([button]) => buttons.push(button));
      if (data.irControl.labels) {
        setActiveButtons(Object.entries(data.irControl.buttons)
          .map(([keyLabel, val]) => buttons.find((button) => {
            return keyLabel === button && val !== -1;
          }))
          .filter((value) => value));
      }
      setIsLoading(false);
    }
    getControl();
  }, []);

  const handlerControl = (updatedControl) => {
    setControl(
      updatedControl,
    );
    buttons = [];
    Object.entries(control?.buttons)
      .forEach(([button]) => buttons.push(button));
    setActiveButtons(Object.entries(control.labels)
      .map(([keyLabel]) => buttons.find((button) => keyLabel === button))
      .filter((value) => value));
  };

  const editNameButton = async () => {
    setChangeName(!changeName);
  };

  const editActive = async () => {
    setActive(!active);
    if (!active) { setChangeName(false); }
  };

  return (
    <div className="ui card controls custom container center aligned">

      <div className="turnon-button turn">
        <p>Edição de dispositivo</p>
        <Checkbox toggle className="green" checked={active} onClick={() => editActive()} />
        <div className="ui inverted dimmer">
          <div className="ui loader" />
        </div>
      </div>

      {
        !active ? (
          <div className="turnon-button turn">
            <p>Alterar Nomes</p>
            <Checkbox
              toggle
              className="green"
              checked={changeName}
              onClick={() => editNameButton()}
            />
            <div className="ui inverted dimmer">
              <div className="ui loader" />
            </div>
          </div>
        ) : null
        }

      {
        isLoading ? <RenderLoading /> : (
          <div className="ui content doubling stackable ten column grid center aligned">
            {
              control.labels && Object.entries(control.labels).map(([key, label]) => {
                return (
                  <ItemCustomControl
                    key={key}
                    btn={label}
                    labelKey={key}
                    active={activeButtons.includes(key)}
                    edit={active}
                    handlerState={handlerControl}
                    changeName={changeName}
                    idDevice={id}
                  />
                );
              })
            }
          </div>
        )
          }
    </div>
  );
}
