import React, { useEffect, useState } from 'react';
import { Button, Message } from 'semantic-ui-react';

import api from '../../../api/api';
import { getMessage } from '../../../api/errors';

const FirmwareUpdater = ({ device, onUpdate }) => {
  const [error, setError] = useState(null);
  const [sending, setSending] = useState(false);
  const [newVersion, setNewVersion] = useState(null);

  useEffect(async () => {
    async function fetchData() {
      const { data } = await api.get(`devices/${device._id}/firmware/${device.model._id}/${device.info?.firmwareVersion}`);
      setNewVersion(data);
    }
    fetchData();
  }, []);

  const upgradeDevice = async () => {
    setSending(true);
    setError(null);
    try {
      await api.post(`devices/${device._id}/firmware/upgrade`, { versionId: newVersion._id });
      onUpdate(newVersion.version);
      setNewVersion(null);
    } catch (e) {
      setError(getMessage(e?.response?.data));
    } finally {
      setSending(false);
    }
  };

  const renderMessageNewVersion = () => {
    return (
      <div className="ui info message">
        <div className="header">
          Atualização disponível!
          <Button primary floated="right" loading={sending} content="Atualizar" onClick={upgradeDevice} />
        </div>
        <p>
          {`Clique no botão ao lado para atualizar para a versão ${newVersion.version}`}
        </p>
      </div>
    );
  };

  const renderError = () => {
    return (
      <Message
        onDismiss={() => setError(null)}
        header="Não foi possível atualizar o dispositivo!"
        content={error}
        error
      />
    );
  };

  if (!newVersion) return '';
  return (
    <>
      {renderMessageNewVersion()}
      {error ? renderError() : null}
    </>
  );
};

export default FirmwareUpdater;
