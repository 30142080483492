import React from 'react';

export function RenderNoResults({ messageHeader, messageParagraph }) {
  return (
    <div className="ui info message">
      <div className="header">
        { messageHeader }
      </div>
      <p>
        { messageParagraph }
      </p>
    </div>
  );
}
