import React from 'react';
import DeviceChildrenList from '../../DeviceChildrenList';

export function RenderChildren({ label, device, offline }) {
  return (
    <>
      <h2>
        {label}
      </h2>
      <DeviceChildrenList device={device} offline={Boolean(offline)} />
    </>
  );
}
