import React from 'react';

import 'react-day-picker/dist/style.css';

import { format } from 'date-fns';
import { DayPicker } from 'react-day-picker';

const pastMonth = Date.now();

export default function DateSelector({ range, setRange }) {
  let footer = <p>Selecione o primeiro dia</p>;
  if (range?.from) {
    if (!range.to) {
      footer = <p>{format(range.from, 'PPP')}</p>;
    } else if (range.to) {
      footer = (
        <p>
          {format(range.from, 'PPP')}
          –
          {format(range.to, 'PPP')}
        </p>
      );
    }
  }

  return (
    <DayPicker
      styles={{
        caption: { marginLeft: 150 },
      }}
      mode="range"
      defaultMonth={pastMonth}
      selected={range}
      footer={footer}
      onSelect={setRange}
    />
  );
}
