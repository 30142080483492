import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import api from '../../../api/api';

const Breadcrumb = ({ deviceId, sensor }) => {
  const [device, setDevice] = useState();
  const renderLink = (deviceToLink) => {
    return (
      <>
        <div className="divider"> / </div>
        <Link className="section" to={`/devices/${deviceToLink._id}`}>
          {deviceToLink.name}
        </Link>
      </>
    );
  };

  useEffect(() => {
    async function fetchData() {
      const { data } = await api.get(`devices/${deviceId}`);
      setDevice(data);
    }
    fetchData();
  }, []);

  return (
    <div className="ui breadcrumb">
      <Link className="section" to="/devices"> Dispositivos </Link>
      { device?.parent ? renderLink(device.parent) : '' }
      { device ? renderLink(device) : '' }
      <div className="divider"> / </div>
      <div className="active section">{sensor.label}</div>
    </div>
  );
};

export default Breadcrumb;
