/* eslint-disable no-param-reassign */
/* eslint-disable no-prototype-builtins */
import React, { useState } from 'react';
import DeviceChildRestart from '../DeviceChildRestart';
import DeviceChildTurnOn from '../DeviceChildTurnOn/DeviceChildTurnOn';
import './DeviceChildItem.less';
import { Outlet } from './Outlet/Outlet';
import { AgendamentoAcao } from './Agendamento/Agendamento';
import { InfoSensors } from './InfoSensors/InfoSensors';

const DeviceChildItem = ({ child, offline, newIndex }) => {
  const [on, setOn] = useState(child.turnedOn);

  return (
    <div className={`item ${on ? 'device-on' : 'device-off'} container`}>
      <div className="first">
        <Outlet
          idOutlet={child._id}
          outletName={child.name}
          description={`${child.model.label} ${newIndex}`}
        />
        <InfoSensors
          id={child._id}
          haveDigital={child.model?.skills?.includes('Turn') && !child.model.actions.includes('pulse')}
        />
      </div>
      <div className="actions">
        { child.model.skills.includes('Schedule') ? <AgendamentoAcao idOutled={child._id} /> : null}
        {child.model?.skills?.includes('Reset')
          ? (
            <DeviceChildRestart
              child={child}
              on={on}
              onChangeState={(state) => setOn(state)}
              disabled={offline}
            />
          )
          : null }
        {child.model?.skills?.includes('Turn') ? (
          <DeviceChildTurnOn
            child={child}
            on={on}
            onChangeState={(state) => setOn(state)}
            disabled={offline}
          />
        ) : null}
      </div>

    </div>
  );
};

export default DeviceChildItem;
