import React, { useRef, useEffect, useState } from 'react';
import Chart from 'chart.js';
import { DateTime } from 'luxon';
import { Message } from 'semantic-ui-react';
import 'chartjs-plugin-annotation';
import 'chartjs-plugin-streaming';

import { addSensorsListener, stopSensorsListener } from '../../../api/socket';
// import { addSensorsListener, stopSensorsListener } from '../../../api/socket';
import api from '../../../api/api';

const RealtimeChart = ({ deviceId, sensor }) => {
  const contSeconds = 5 * 1000;
  const [device, setDevice] = useState(null);
  const [showDevice, setShowDevice] = useState(true);
  const [dataOff, setDataOff] = useState(null);
  const [callApi, setCallApi] = useState(0);
  const chartRef = useRef();
  const [charts, setChartTs] = useState(null);
  const renderChart = (lastMeasurement) => {
    /**
     * Se no momento da renderização do gráfico (depois que carregou os dados), o usuário navegar
     * para fora da tela,o chartRef.current vai estar zerado. Por isso, essa checagem é feita
     */
    if (!chartRef.current) return null;

    return new Chart(chartRef.current.getContext('2d'), {
      type: 'line',
      data: {
        datasets: [
          {
            label: sensor.name,
            data: [{ x: Date.now(), y: lastMeasurement }],
            borderColor: '#2c85d0',
            borderWidth: 1,
            backgroundColor: '#2c85d0',
            lineTension: 0,
            interpolate: true,
            fill: false,
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: false,
        },
        hover: {
          intersect: false,
        },
        tooltips: {
          intersect: false,
          mode: 'index',
          callbacks: {
            label: (tooltipItems, data) => {
              const label = data.datasets[tooltipItems.datasetIndex].label || '';
              return `${label}: ${tooltipItems.yLabel} ${sensor?.model.unit.short}`;
            },
          },
        },
        elements: {
          point: {
            radius: 0,
          },
        },
        scales: {
          yAxes: [{
            // grace: '10%',
            ticks: {
              beginAtZero: true,
              callback: (label) => {
                return `${label} ${sensor?.model.unit.short}`;
              },
            },
          }],
          xAxes: [{
            type: 'realtime',
            time: {
              tooltipFormat: 'HH:mm:ss',
              displayFormats: {
                second: 'HH:mm:ss',
                minute: 'HH:mm:ss',
              },
            },
            ticks: {
              // maxTicksLimit: 5,
              autoSkip: false,
              maxRotation: 0,
              callback: (measuredAt) => {
                return measuredAt;
              },
            },
            realtime: {
              delay: 1000,
              refresh: 3 * 1000,
              duration: 60 * 1000,
              onRefresh(chart) {
                const { data } = chart.data.datasets[0];
                const lastPoint = data[data.length - 1];
                if (lastPoint) {
                  data.push({ x: Date.now(), y: lastPoint.y });
                }
              },
            },
          }],
        },
      },
    });
  };
  const attShowDevice = async () => {
    await api.get(`devices/${deviceId}`).then(({ data }) => {
      setShowDevice(!data?.offlineSince ? !data?.offlineSince : null);
      setDataOff(DateTime.fromISO(data.offlineSince).toFormat('dd/MM/yyyy HH:mm'));
      setDevice(data);
    });
  };

  const handleChanges = async (changes, chart) => {
    const actualSensor = changes.find(
      (change) => change.sensorId === sensor.sensorId && change.sensorType === sensor.sensorType,
    );
    chart?.data.datasets[0].data.push({
      x: Date.now(),
      y: actualSensor.value,
    });
    chart?.update();
    await attShowDevice();
  };
  useEffect(() => {
    attShowDevice();
  }, []);
  useEffect(() => {
    if (!device) return {};
    if (charts === null) {
      const chart = renderChart(sensor.value);
      setChartTs(chart);
    }
    return () => {
      if (showDevice !== !device?.offlineSince) {
        if (charts) setChartTs(null);
      }
    };
  }, [device, charts]);
  useEffect(() => {
    setTimeout(() => {
      setCallApi((prev) => prev + 1);
      attShowDevice();
    }, contSeconds);
  }, [callApi]);

  useEffect(() => {
    if (device?.physicalId) {
      addSensorsListener(device.physicalId, (changes) => handleChanges(changes, charts));
    }
    return () => {
      stopSensorsListener(device?.physicalId);
    };
  }, [device]);
  return (
    <div className="ui basic segment zero-padding sensor chart">
      {
        showDevice
          ? <canvas ref={chartRef} />
          : (
            <Message
              header={`Dispositivo offline desde ${dataOff}`}
              warning
              icon="exclamation triangle"
            />
          )
      }
    </div>
  );
};
export default RealtimeChart;
