import Chart from 'chart.js';
import { DateTime } from 'luxon';

const fixMeasureTwoDecimalNumbers = (measure) => {
  return Number.parseFloat(measure).toFixed(2);
};
const rotationOfGraph = (screenWidth) => {
  if (screenWidth <= 1400) {
    return 45;
  }
  return 0;
};
const renderChart = (measures,
  insideDay,
  paramTimebox,
  paramTimeboxOptions,
  refChart, sensor) => {
  Chart.Tooltip.positioners.top = (elements, position) => {
    return {
      x: position.x,
      y: 0,
    };
  };
  /**
      * Se no momento da renderização do gráfico (depois que carregou os dados), o usuário navegar
      * para fora da tela,o chartRef.current vai estar zerado. Por isso, essa checagem é feita
      */
  if (!refChart.current) return null;
  // let thresholdLine = {};
  const screenWidth = window.innerWidth;
  return new Chart(refChart.current.getContext('2d'), {
    type: 'line',
    data: {
      // eslint-disable-next-line max-len
      labels: measures.length > 0 ? measures.map((measure) => {
        return measure.measuredAt;
      }) : '',
      datasets: [
        {
          label: 'Média',
          data: measures.map((measure, index) => {
            return { x: index, y: fixMeasureTwoDecimalNumbers(measure.avg) };
          }),
          borderColor: '#2c85d0',
          borderWidth: 1,
          backgroundColor: '#2c85d0',
          lineTension: 0,
          interpolate: true,
          fill: false,
        },
        {
          label: 'Máxima',
          data: measures.map((measure, index) => {
            return { x: index, y: fixMeasureTwoDecimalNumbers(measure.max) };
          }),
          borderColor: '#2e87d124',
          borderWidth: 1,
          backgroundColor: '#2e87d124',
          lineTension: 0,
          interpolate: true,
          fill: 2,
        },
        {
          label: 'Mínima',
          data: measures.map((measure, index) => {
            return { x: index, y: fixMeasureTwoDecimalNumbers(measure.min) };
          }),
          borderColor: '#2e87d124',
          backgroundColor: '#2e87d124',
          borderWidth: 1,
          fill: false,
          lineTension: 0,
          interpolate: true,
        },
      ],
    },
    options: {
      responsive: true,
      maintainAspectRatio: false,
      legend: {
        display: false,
      },
      hover: {
        intersect: false,
      },
      tooltips: {
        intersect: false,
        mode: 'index',
        callbacks: {
          label: (tooltipItems, data) => {
            const label = data.datasets[tooltipItems.datasetIndex].label || '';
            return `${label}: ${tooltipItems.yLabel} ${sensor?.model.unit?.short}`;
          },
          title: (values) => {
            if (values[0]) {
              return paramTimeboxOptions[paramTimebox.type]
                .tooltipTitle(DateTime.fromISO(values[0].label));
            }
            return null;
          },
        },
      },
      elements: {
        point: {
          radius: 0,
        },
      },
      scales: {
        yAxes: [{
          ticks: {
            fontSize: 12,
            beginAtZero: true,
            callback: (label) => {
              return `${label} ${sensor?.model.unit.short}`;
            },
          },
        }],
        xAxes: [{
          ticks: {
            fontSize: 10,
            borderWidth: 1,
            borderColor: '#2c85d0',
            autoSkip: false,
            maxRotation: rotationOfGraph(screenWidth),
            callback: (value, index) => paramTimeboxOptions[paramTimebox.type]
              .tickLabel(value, index, { insideDay, screenWidth }),
          },
          gridLines: {
          },
          borderColor: '#f00',
          borderWidth: 1,
        }],
      },
      plugins: {
        crosshair: {
          line: {
            color: '#a0a9b0',
            width: 1,
            dashPattern: [1, 1],
          },
          snap: {
            enabled: true,
          },
          sync: {
            enabled: false,
          },
          zoom: {
            enabled: false,
          },
        },
      },
    },
  });
};

export { renderChart };
