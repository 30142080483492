import React, { useState, useEffect } from 'react';
import api from '../../../../../api/api';
import ButtonResponsive from '../../../../elements/ButtonResponsive';

export function RenderUsers({ id }) {
  const [accessList, setAccessList] = useState([]);

  const handleDelete = async (accessId) => {
    try {
      await api.delete(`devices/${id}/user/${accessId}`);
      const newList = accessList.filter((acesso) => acesso._id !== accessId);
      setAccessList(newList);
    } catch {
      // eslint-disable-next-line no-console
      console.log('err0');
    }
  };

  useEffect(() => {
    async function fetchData() {
      const { data } = await api.get(`devices/${id}/user/`);
      setAccessList(data);
    }
    fetchData();
  }, []);
  return (
    <div className="ui raised segment chidren-devices">
      <div className="divided items ui unstackable">
        {
            !accessList.length
              ? (
                <p>
                  Somente você tem acesso a este dispositivo.
                  Clique em Adicionar usuário para conceder acesso a outras pessoas.
                </p>
              )
              : (
                accessList.map((deviceAccess) => (
                  <div key={deviceAccess._id} className="item">
                    <div className="content">
                      <div className="header">{deviceAccess.user?.name || 'Cadastro pendente'}</div>
                      <div className="description">
                        {deviceAccess.email}
                      </div>
                    </div>
                    <div className="extra">
                      <ButtonResponsive floated="right" icon="trash" content="Remover" onClick={() => handleDelete(deviceAccess._id)} />
                    </div>
                  </div>
                ))
              )
        }
      </div>
    </div>
  );
}
