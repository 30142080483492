import React from 'react';
import { Link } from 'react-router-dom';

const Breadcrumb = ({ device }) => (
  <div className="ui breadcrumb">
    <Link className="section" to="/devices"> Dispositivos </Link>
    { device.parent ? (
      <>
        <div className="divider"> / </div>
        <Link className="section" to={`/devices/${device.parent._id}`}>
          {device.parent.name}
        </Link>
      </>
    ) : '' }
    <div className="divider"> / </div>
    <div className="active section">{device.name}</div>
  </div>
);

export default Breadcrumb;
