/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import { Formik, Form } from 'formik';
import { Button, Input } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

import SemanticField from '../elements/SemanticField';
import { getMessage } from '../../api/errors';

const CodeValidation = ({ timeout, onClickBack, onSubmit }) => {
  const [sending, setSending] = useState(false);
  const [error, setError] = useState(null);
  const [expiresIn, setExpiresIn] = useState(timeout);
  const initialValues = {
    email: '',
  };

  useEffect(() => {
    if (expiresIn <= 0) return;
    setTimeout(() => {
      setExpiresIn(expiresIn - 1);
    }, 1000);
  }, [expiresIn]);

  const handleSubmit = async (values) => {
    setSending(true);
    try {
      await onSubmit(values.code);
    } catch (e) {
      setError(getMessage(e.response.data));
    } finally {
      setSending(false);
    }
  };

  const renderError = (msg) => {
    return msg ? (
      <div className="ui negative message">
        <p>{msg}</p>
      </div>
    ) : '';
  };

  const renderTimeout = () => {
    return (
      <p>
        {expiresIn > 0 ? `Expira em: ${expiresIn}` : 'Código expirado'}
      </p>
    );
  };

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit}>
      <Form className={`ui large form ${sending ? 'loading' : ''}`}>
        <p>
          Foi enviado um código de confirmação de 6 dígitos para o email informado.
          Digite a seguir o código:
        </p>
        <div className="field">
          <SemanticField
            name="code"
            component={Input}
            icon="key"
            iconPosition="left"
            required
            placeholder="Código"
            size="big"
            maxLength="6"
          />
        </div>
        {renderError(error) || renderTimeout()}
        <div className="field">
          <Button
            content="Continuar"
            primary
            fluid
            size="huge"
          />
        </div>
        <div className="field">
          <Link onClick={onClickBack}>Voltar</Link>
        </div>
      </Form>
    </Formik>
  );
};

export default CodeValidation;
