import React, { useEffect, useState } from 'react';
import { Button } from 'semantic-ui-react';
import { DateTime } from 'luxon';

import ScheduleForm from './ScheduleForm';
import api from '../../../api/api';

import './Schedule.less';

const weekdays = {
  1: 'Segunda',
  2: 'Terça',
  3: 'Quarta',
  4: 'Quinta',
  5: 'Sexta',
  6: 'Sábado',
  7: 'Domingo',
};

const Schedule = ({ deviceId, actions, offline }) => {
  const [schedules, setSchedules] = useState([]);
  const timezone = DateTime.local().zoneName;

  const utcToLocalSchedule = (schedule) => {
    const utcTime = DateTime.fromFormat(schedule.time, 'HH:mm', { zone: 'utc' }).set({ weekday: schedule.weekday });
    const localTime = utcTime.setZone(timezone);
    const time = localTime.toFormat('HH:mm');

    return {
      ...schedule,
      time,
      weekday: localTime.weekday,
    };
  };

  const convertSchedules = (s) => s.map(utcToLocalSchedule);

  useEffect(() => {
    async function fetchData() {
      const { data } = await api.get(`devices/${deviceId}/schedule/`);
      setSchedules(convertSchedules(data));
    }

    fetchData();
  }, []);

  const handleCreation = (newSchedules) => {
    const newList = [...schedules, ...convertSchedules(newSchedules)];
    setSchedules(newList.sort((a, b) => a.time.localeCompare(b.time)));
  };

  const handleUpdate = (newSchedules, scheduleId) => {
    const newList = schedules.filter((s) => s._id !== scheduleId);
    setSchedules([...newList, ...convertSchedules(newSchedules)].sort((a, b) => {
      return a.time.localeCompare(b.time);
    }));
  };

  const handleDelete = (scheduleId) => {
    const newList = schedules.filter((s) => s._id !== scheduleId);
    setSchedules(newList);
  };

  const renderSchedules = (day) => {
    return schedules.filter((s) => s.weekday.toString() === day).map((schedule) => {
      return (
        <div key={schedule._id} className="item">
          <ScheduleForm
            deviceId={deviceId}
            actions={actions}
            onSave={handleUpdate}
            onDelete={handleDelete}
            schedule={{ ...schedule, action: schedule.action._id }}
            offline={offline}
          >
            <div className="ui blue label" style={{ cursor: 'pointer' }}>
              <span className="time">{schedule.time}</span>
              <div className="detail">{schedule.action.label}</div>
            </div>
          </ScheduleForm>
        </div>
      );
    });
  };

  const renderWeekdays = () => {
    return Object.keys(weekdays).map((dayNumber) => {
      return (
        <td key={dayNumber}>
          <div className="ui list">
            {renderSchedules(dayNumber)}
          </div>
        </td>
      );
    });
  };

  const renderMobile = () => {
    return (
      <table className="ui definition unstackable table raised segment mobile">
        <tbody>
          {
            Object.keys(weekdays).map((dayNumber) => {
              return (
                <tr key={dayNumber}>
                  <td>{weekdays[dayNumber]}</td>
                  <td>
                    <div className="ui list">
                      {renderSchedules(dayNumber)}
                    </div>
                  </td>
                </tr>
              );
            })
          }
        </tbody>
      </table>
    );
  };

  const renderDesktop = () => {
    return (
      <table className="ui celled fixed unstackable table raised segment desktop">
        <thead>
          <tr className="center aligned">
            {Object.values(weekdays).map((day) => <th key={day}>{day}</th>)}
          </tr>
        </thead>
        <tbody>
          <tr className="top aligned">
            {renderWeekdays()}
          </tr>
        </tbody>
      </table>
    );
  };

  return (
    <div className="schedule">
      <h2>
        Agenda de ações
        <ScheduleForm
          deviceId={deviceId}
          actions={actions}
          onSave={handleCreation}
          offline={offline}
        >
          <Button circular icon="plus" disabled={offline} />
        </ScheduleForm>
      </h2>
      <p>
        {`Fuso horário: ${timezone}`}
      </p>
      {renderDesktop()}
      {renderMobile()}
    </div>
  );
};

export default Schedule;
