import React, { useState } from 'react';
// import { Input } from 'semantic-ui-react';
import { Field } from 'formik';

// import SemanticField from '../SemanticField';

const PasswordField = ({ name = 'password', placeholder = 'Senha', showLeftIcon = true }) => {
  const [passwordVisible, setPasswordVisible] = useState(false);

  const handleEyeClick = () => {
    setPasswordVisible(!passwordVisible);
  };

  return (
    <div className={`ui ${showLeftIcon ? 'left' : ''} icon big input`}>
      {showLeftIcon ? <i aria-hidden="true" className="lock icon" /> : null}
      <Field name={name} type={passwordVisible ? 'text' : 'password'} placeholder={placeholder} required maxLength="10" />
      <i onClick={handleEyeClick} aria-hidden="true" className={`eye ${passwordVisible ? 'slash' : ''} link icon right`} />
    </div>
  );
};

export default PasswordField;
