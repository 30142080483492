import React from 'react';
import { Progress } from 'semantic-ui-react';
import './Sensor.less';

function getColor(value, threshold) {
  return value >= threshold ? 'red' : 'blue';
}

function digital(value, model, config) {
  if (!model) return null;
  return (
    <>
      {config !== undefined
        ? (<Progress percent={100} color={value !== config.threshold ? 'green' : 'red'} />)
        : (<Progress percent={100} color={!value ? 'green' : 'red'} />) }
      <div className="value sensor">{ value ? model.digital.on : model.digital.off }</div>
    </>
  );
}
function pontoToVirgula(value) {
  const string = String(value);
  return string.replace('.', ',');
}
function analogic(value, model, config, inError) {
  if (!model) return null;
  const threshold = config?.threshold || model?.analogic?.threshold;
  return (
    <>
      <Progress
        percent={(value / model.analogic.max) * 100}
        color={getColor(value, threshold)}
      />
      <div className="value sensor">
        <p className="labelSensor">{ inError ? 'Erro' : `${pontoToVirgula(value)} ${model.unit.short}` }</p>
      </div>
    </>
  );
}

function Sensor({ value = 0, sensor }) {
  if (sensor?.model?.type === 'digital') return digital(value, sensor.model, sensor?.config);
  if (sensor?.model?.type === 'analogic') return analogic(value, sensor.model, sensor?.config, sensor.inError);
  return null;
}

export default Sensor;
