/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from 'react';
import './controls.less';
import { FaFan } from 'react-icons/fa';
import { CgArrowsExchangeV } from 'react-icons/cg';
import { TiWaves } from 'react-icons/ti';
import { BsSnow } from 'react-icons/bs';
import { useParams } from 'react-router-dom';
import { RenderLoading } from '../../../renders/RenderLoading';
import { sendSignal } from './sendSignal';

export function ArControl() {
  const { id, idControl } = useParams();
  const [errorSendSignalIr, setErrorSendSignalIr] = useState('');
  const [isLoadingSignal, setIsLoadingSignal] = useState(false);
  if (isLoadingSignal) return <RenderLoading />;
  return (
    <div className="ui card controls">
      <div className="ui content ar">
        <div className="first">
          <div
            className="icone plus"
            onClick={async () => sendSignal({
              id,
              idControl,
              button: 'TEMPERATURE_UP',
              changeError: setErrorSendSignalIr,
              changeLoading: setIsLoadingSignal,
            })}
          >
            <i className="plus icon icons" />
          </div>
          <div
            className="icone power ar"
            onClick={async () => sendSignal({
              id,
              idControl,
              button: 'POWER',
              changeError: setErrorSendSignalIr,
              changeLoading: setIsLoadingSignal,
            })}
          >
            <i className="power off icon icons" />
          </div>
          <div
            className="icone minus"
            onClick={async () => sendSignal({
              id,
              idControl,
              button: 'TEMPERATURE_DOWN',
              changeError: setErrorSendSignalIr,
              changeLoading: setIsLoadingSignal,
            })}
          >
            <i className="minus icon icons" />
          </div>
        </div>
        <div className="second">
          <div
            style={{ textAlign: 'center' }}
            onClick={async () => sendSignal({
              id,
              idControl,
              button: 'FAN',
              changeError: setErrorSendSignalIr,
              changeLoading: setIsLoadingSignal,
            })}
          >
            <div className="icone iconsSecond">
              <FaFan className="icon icons" />
            </div>
            <p>Fan</p>
          </div>
          <div
            style={{ textAlign: 'center' }}
            onClick={async () => sendSignal({
              id,
              idControl,
              button: 'FAN_SPEED',
              changeError: setErrorSendSignalIr,
              changeLoading: setIsLoadingSignal,
            })}
          >
            <div className="icone iconsSecond">
              <TiWaves className="icon icons" />
            </div>
            <p>Fan Speed</p>
          </div>
          <div
            style={{ textAlign: 'center' }}
            onClick={async () => sendSignal({
              id,
              idControl,
              button: 'AIR_SWING',
              changeError: setErrorSendSignalIr,
              changeLoading: setIsLoadingSignal,
            })}
          >
            <div className="icone iconsSecond">
              <CgArrowsExchangeV className="icon icons" />
            </div>
            <p>Air Swing</p>
          </div>
          <div
            style={{ textAlign: 'center' }}
            onClick={async () => sendSignal({
              id,
              idControl,
              button: 'COOL',
              changeError: setErrorSendSignalIr,
              changeLoading: setIsLoadingSignal,
            })}
          >
            <div className="icone iconsSecond">
              <BsSnow className="icon icons" />
            </div>
            <p>Cool</p>
          </div>
        </div>
      </div>
      {
        errorSendSignalIr ? (
          <p className="error">
            Erro ao enviar o sinal:
            {' '}
            {errorSendSignalIr}
          </p>
        ) : null
      }
    </div>
  );
}
