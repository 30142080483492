export const messages = {
  REQUEST_TIMEOUT: 'Não foi possível se comunicar com o dispositivo.',
  EMAIL_EXISTS: 'Email já cadastrado. Tente outro email.',
  EMAIL_CODE_INVALID: 'Código inválido',
  PASSWORD_INVALID: 'Senha incorreta',
  PASSWORD_WEAK: 'A senha precisa conter entre 6 e 10 caracteres, contendo no mímino um número e uma letra',
  EMAIL_UNREGISTRED: 'Email não cadastrado',
  USER_ALREADY_ACCESS: 'Usuário já tem acesso ao dispositivo',
  DEVICE_ALREADY_HAS_OWNER: 'Dispositivo já foi cadastrado por outro usuário.',
  INVALID_TOKEN: 'A senha deve ter 8 números',
  SCHEDULE_FULL: 'O limite de agendamentos foi atingido.',
  SCHEDULE_NOT_FOUND: 'Este agendamento não foi encontrado no dispositivo.',
  UPGRADE_INTERNAL_ERROR: 'Houve um erro no dispositivo durante a atualização',
};

export const getMessage = (errorCode) => {
  return messages[errorCode] || errorCode;
};

export default { messages, getMessage };
