/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { RenderLoading } from '../../../renders/RenderLoading';
import api from '../../../../api/api';
import './controls.less';
import { TVControl } from './TVControl';
import { ArControl } from './ArControl';
import { CustomControl } from './CustomControl';

export function ControlIr() {
  const { id, idControl } = useParams();
  const [errorIr, setErrorIr] = useState('');
  const [typeIrControl, setTypeIrControl] = useState('');
  const [loadingIr, setLoadingIr] = useState(false);
  const [nameDevice, setNameDevice] = useState('');
  const [nameControl, setNameControl] = useState('');
  useEffect(() => {
    async function getDevice() {
      try {
        const { data } = await api.get(`/devices/${id}/`);
        setNameDevice(data.model.name);
      } catch (error) {
        setErrorIr(error.response.data.error);
      }
    }
    async function getIrControl() {
      setLoadingIr(true);
      try {
        const { data } = await api.get(`/devices/${id}/ir/${idControl}`);
        setTypeIrControl(data.irControl.equipment);
        setNameControl(data.irControl.name);
      } catch (error) {
        setErrorIr(error.response.data.error);
      }
      setLoadingIr(false);
    }
    getDevice();
    getIrControl();
  }, []);
  if (loadingIr) return RenderLoading();
  function showControl() {
    if (typeIrControl === 'TV') return <TVControl />;
    if (typeIrControl === 'Ar-condicionado') return <ArControl />;
    return <CustomControl />;
  }
  return (
    <>
      <div className="ui breadcrumb">
        <Link className="section" to="/devices"> Dispositivos </Link>
        <div className="divider"> / </div>
        <Link className="section" to={`/devices/${id}`}>{nameDevice}</Link>
        <div className="divider"> / </div>
        <p className="active section">
          {nameControl}
        </p>
        <div className="ui container div" />
        <h1>Controles</h1>
        { showControl()}
        {
        errorIr ? (
          <p className="error">
            Erro ao carregar controle:
            {' '}
            {errorIr}
          </p>
        ) : null
      }
      </div>
    </>
  );
}
