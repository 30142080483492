import React, { useEffect, useState } from 'react';
import './InfoSensors.less';
import Sensor from '../../../sensor/Sensor';
import api from '../../../../../api/api';
import { addSensorsListener, stopSensorsListener } from '../../../../../api/socket';

export function InfoSensors({ id, haveDigital }) {
  const [measures, setMeasures] = useState([]);
  const [device, setDevice] = useState(null);
  useEffect(() => {
    async function fetchData() {
      api.get(`devices/${id}`).then(({ data }) => {
        setMeasures(data.sensors || []);
        setDevice(data);
      });
    }
    fetchData();
  }, []);
  useEffect(() => {
    if (device) {
      addSensorsListener(device.physicalId, (changes) => {
        setMeasures(changes);
      });
    }
    return () => {
      stopSensorsListener(device?.physicalId);
    };
  }, [device]);

  const widget = (sensor) => {
    return (
      <div key={sensor.name} className="sensor-box">
        <p style={{ margin: -2 }} title={sensor.name}>{sensor.name.length > 8 ? `${sensor.name.substring(0, 8)}...` : sensor.name}</p>
        <Sensor value={sensor.value} sensor={sensor} />
      </div>
    );
  };

  return (
    <div className="sensors hideable">
      {measures.length > 0 && (
        measures.map((sensor) => (
          <div key={sensor.name}>
            {!haveDigital ? widget(sensor) : (
              <>
                { sensor.sensorType !== 'turnedOn' ? widget(sensor) : null}
              </>
            ) }
          </div>
        )))}
    </div>
  );
}
