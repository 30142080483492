import React from 'react';
import { NavLink } from 'react-router-dom';

import auth from '../../services/auth';

const SidebarMenu = ({ onClick }) => {
  const logout = () => {
    auth.logout();
    if (onClick) onClick();
  };

  return (
    <>
      <NavLink onClick={onClick} to="/devices" className="item">
        Dispositivos
      </NavLink>
      <NavLink onClick={onClick} to="/logs" className="item">
        Logs
      </NavLink>
      {/* <NavLink exact to="/users" className="item">
        Usuários
      </NavLink> */}
      <NavLink onClick={onClick} exact to="/user/password/change" className="item">
        Alterar senha
      </NavLink>
      <NavLink className="item" onClick={logout} to="/login">Sair</NavLink>

      {/* <Menu.Item as='a'>
<Icon name='home' />
Home
</Menu.Item>
<Menu.Item as='a'>
<Icon name='gamepad' />
Games
</Menu.Item>
<Menu.Item as='a'>
<Icon name='camera' />
Channels
</Menu.Item> */}
    </>
  );
};

export default SidebarMenu;
