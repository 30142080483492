/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { Button, Modal } from 'semantic-ui-react';
import './controls.less';
import { useParams } from 'react-router-dom';
import { AiOutlineClose } from 'react-icons/ai';
import { MdOutlineVerifiedUser } from 'react-icons/md';
import api from '../../../../api/api';
import { sendSignal } from './sendSignal';
import EditableLabel from '../../../elements/EditableLabel/EditableLabel';

export function ItemCustomControl({
  btn, labelKey, active, edit, handlerState, changeName, idDevice,
}) {
  const { id, idControl } = useParams();
  const [openModal, setOpenModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorSignal, setErrorSignal] = useState(false);
  const [isActive, setIsActive] = useState(active);
  const [setError] = useState('');

  const editNameButton = async (name) => {
    if (name === btn) return;
    setIsLoading(true);
    await api
      .put(`devices/${id}/ir/${idControl}/editNameControlButton`, { idDevice, idControl, labelKey, name })
      .then(() => {
        window.location.reload();
      })
      .catch(() => {
        console.warn('error');
      });
    setIsLoading(false);
  };

  async function getSignal() {
    setOpenModal(true);
    setIsLoading(true);
    try {
      const res = await api.post(`/devices/${id}/ir/${idControl}/read`, {
        button: labelKey,
      });
      handlerState(res.data);
      setIsActive(true);
    } catch {
      setErrorSignal(true);
    }
    setIsLoading(false);
  }
  function show() {
    if (isLoading) {
      return (
        <div className="ui card loading">
          <div className="ui inverted dimmer active">
            <div className="ui text loader">Procurando...</div>
          </div>
        </div>
      );
    }
    if (errorSignal) {
      return (
        <span className="response error">
          <AiOutlineClose />
          Botão não verificado
        </span>
      );
    }
    return (
      <span className="response work">
        <MdOutlineVerifiedUser />
        Botão verificado
      </span>
    );
  }
  async function makeSignal() {
    if (edit) {
      await getSignal();
    } else {
      await sendSignal({
        button: labelKey,
        changeError: setErrorSignal,
        changeLoading: setIsLoading,
        id,
        idControl,
      });
    }
  }
  return (
    <>
      <div key={btn} className={!changeName ? 'divBtn' : ''} style={{ height: '25px' }}>

        { !changeName ? (
          <Button
            className={`btnsCustom ${isActive ? 'checked' : ''}`}
            style={{ marginTop: '10px' }}
            onClick={async () => makeSignal()}
          >
            {btn}
          </Button>
        )
          : (
            <div style={{ background: '#E0E1E2', height: '85px', width: '85px', borderRadius: '50% 50%' }}>
              <div style={{ paddingTop: '36px' }}>
                <EditableLabel
                  value={btn}
                  onSubmit={editNameButton}
                />
              </div>
            </div>
          )}
      </div>
      <Modal
        onClose={() => setOpenModal(false)}
        open={openModal}
        size="mini"
      >
        <Modal.Header style={{ textAlign: 'center' }}>
          Pressione a tecla do controle
        </Modal.Header>
        <Modal.Content scrolling className="modalContent">
          <p>Esperando sinal do controle...</p>
          <span>{btn}</span>
          <Modal.Description className="modalBtn">
            <br />
            {show()}
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={() => setOpenModal(false)}>Fechar</Button>
        </Modal.Actions>
      </Modal>
    </>

  );
}
