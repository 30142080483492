import React from 'react';
import SidebarMenu from './SidebarMenu';

const SidebarDesktop = () => (
  <div className="ui vertical left fixed inverted blue main menu">
    <SidebarMenu />
  </div>
);

export default SidebarDesktop;
