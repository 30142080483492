import React from 'react';
import { Link } from 'react-router-dom';
import { Popup } from 'semantic-ui-react';
import { popupStyle } from '../../PopupStyle';
import './Agendamento.less';

export function AgendamentoAcao({ idOutled }) {
  return (
    <Link to={`/devices/${idOutled}`} className="link-tomada">
      <Popup
        style={popupStyle}
        content="Agendamentos para ligar/desligar a tomada."
        trigger={(
          <i className="calendar alternate outline icon" />
        )}
      />
    </Link>
  );
}
