import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import api from '../../../api/api';
import DeviceCard from '../card/DeviceCard';
import ButtonResponsive from '../../elements/ButtonResponsive';
import { RenderLoading } from '../../renders/RenderLoading';
import { RenderNoResults } from '../../renders/RenderNoResults';

const renderDevices = (devices) => {
  return (
    <div className="ui device cards">
      {devices.map((device) => <DeviceCard key={device._id} device={device} />)}
    </div>
  );
};

const DeviceListOwner = () => {
  const [devices, setDevices] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      const { data } = await api.get('devices');
      setLoading(false);
      setDevices(data);
    }
    fetchData();
  }, []);
  return (
    <>
      <h2 className="ui header">
        Seus Dispositivos
        <span>
          <ButtonResponsive as={Link} to="/devices/new/" content="Adicionar dispositivo" icon="plus" primary floated="right" />
        </span>
      </h2>
      {devices.length || loading ? renderDevices(devices)
        : (
          <RenderNoResults
            messageHeader="Nenhum dispositivo cadastrado"
            messageParagraph="Com o número de série do dispositivo,
            você pode cadastrá-lo para ter acesso ao painel de controle do dispositivo"
          />
        )}
      {/* <DeviceForm onCreateDevice={onCreateDevice} /> */}
      {loading ? <RenderLoading /> : null}
    </>
  );
};

export default DeviceListOwner;
